import React from 'react';
import styled, { css } from 'styled-components/macro';

import { HeroTitle } from './shared/HeroTitle';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-gap: ${theme.spacing(2)}px;

    width: 100%;
    height: 100vh;

    ${theme.breakpoints.down('sm')} {
      padding-top: 0;
    }
  `}
`;

export const HeroBanner: React.FC = () => {
  return (
    <Wrapper>
      <HeroTitle />
    </Wrapper>
  );
};
