import React from 'react';
import styled, { css, ThemeContext } from 'styled-components/macro';
import { Done } from '@material-ui/icons';

import { RoundedIcon } from '..';

import { ReactComponent as FlatBrushSVG } from './assets/FlatBrush.svg';
import { ReactComponent as TitleSvg } from './assets/Title.svg';
import { ReactComponent as BrushSVG } from './assets/Brush.svg';
import { ReactComponent as CheckCircleSVG } from './assets/CheckCircle.svg';
import { ReactComponent as FlagSVG } from './assets/Flag.svg';
import { ReactComponent as FontSizeSVG } from './assets/FontSize.svg';
import { ReactComponent as PenSVG } from './assets/Pen.svg';
import { ReactComponent as PencilSVG } from './assets/Pencil.svg';
import { ReactComponent as SearchSVG } from './assets/Search.svg';
import { ReactComponent as TextHighlightSVG } from './assets/TextHighlight.svg';
import { ReactComponent as MenuDots } from './assets/MenuDots.svg';
import { ReactComponent as Notification } from './assets/Notification.svg';
import { ReactComponent as Message } from './assets/Message.svg';
import { ReactComponent as Assessments } from './assets/Assessments.svg';
import { ReactComponent as GoalSetter } from './assets/GoalSetter.svg';
import { ReactComponent as Journal } from './assets/Journal.svg';
import { ReactComponent as Courses } from './assets/Courses.svg';
import { ReactComponent as Complete } from './assets/Complete.svg';
import { ReactComponent as Book } from './assets/Book.svg';
import { ReactComponent as WgUserNotes } from './assets/WgUserNotes.svg';

const iconBaseStyle = css`
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  width: 1em;
  height: 1em;
`;

export const BwwFlatBrush = styled(FlatBrushSVG)`
  ${iconBaseStyle}
`;
export const BwwTitle = styled(TitleSvg)`
  ${iconBaseStyle}
`;
export const BwwBrush = styled(BrushSVG)`
  ${iconBaseStyle}
`;
export const BwwCheckCircle = styled(CheckCircleSVG)`
  ${iconBaseStyle}
`;
export const BwwFlag = styled(FlagSVG)`
  ${iconBaseStyle}
`;
export const BwwFontSize = styled(FontSizeSVG)`
  ${iconBaseStyle}
`;
export const BwwPencil = styled(PencilSVG)`
  ${iconBaseStyle}
`;
export const BwwSearch = styled(SearchSVG)`
  ${iconBaseStyle}
`;
export const BwwTextHighlight = styled(TextHighlightSVG)`
  ${iconBaseStyle}

  width: inherit;
`;
export const BwwPen = styled(PenSVG)`
  ${iconBaseStyle}
`;

export const BwwMenuDots = styled(MenuDots)`
  ${iconBaseStyle}
`;

export const BwwNotification = styled(Notification)`
  ${iconBaseStyle}
`;

export const BwwMessage = styled(Message)`
  ${iconBaseStyle}
`;

export const BwwAssessments = styled(Assessments)`
  ${iconBaseStyle}
`;

export const BwwGoalSetter = styled(GoalSetter)`
  ${iconBaseStyle}
`;

export const BwwJournal = styled(Journal)`
  ${iconBaseStyle}
`;

export const BwwCourses = styled(Courses)`
  ${iconBaseStyle}
`;

export const BwwComplete = styled(Complete)`
  ${iconBaseStyle}
`;

export const BwwBook = styled(Book)`
  ${iconBaseStyle}
`;

export const BwwWgUserNotes = styled(WgUserNotes)`
  ${iconBaseStyle}
`;

export const BwwDone: React.FC<{}> = () => {
  const theme = React.useContext(ThemeContext);

  return (
    <RoundedIcon
      backgroundColor={theme.palette.common.successGreen}
      iconColor={theme.palette.common.white}
    >
      <Done />
    </RoundedIcon>
  );
};
