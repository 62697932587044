import React from 'react';
import { Content } from 'components';
import { AlgorithmLibrary } from 'views';

export const AlgorithmLibraryScreen = () => {
  return (
    <Content title="Algorithms">
      <AlgorithmLibrary />
    </Content>
  );
};
