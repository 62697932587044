import * as React from 'react';
import styled, { css } from 'styled-components/macro';

interface RoundedIconProps {
  children: JSX.Element;
  backgroundColor?: string | null;
  borderColor?: string | null;
  iconColor?: string | null;
  addDropShadow?: boolean;
  addWhiteBottom?: boolean;
}

const Wrapper = styled.div<{
  borderColor: string | null | undefined;
  iconColor: string | null | undefined;
  addDropShadow: boolean;
  addWhiteBottom: boolean;
}>`
  border-radius: 100%;
  overflow: hidden;

  ${({ theme, borderColor, iconColor, addDropShadow, addWhiteBottom }) => css`
  width: ${theme.spacing(2)}px;
  height: ${theme.spacing(2)}px;

    ${
      addWhiteBottom &&
      css`
        background-color: ${theme.palette.common.white};
      `
    }

    ${
      borderColor &&
      css`
        border: 1px solid ${borderColor};
      `
    }

    ${
      iconColor &&
      css`
        color: ${iconColor};
      `
    }

    ${
      addDropShadow &&
      css`
        box-shadow: ${theme.shadows[3]};
      `
    }

    & svg {
      font-size: ${theme.typography.h3.fontSize};
    }
  `}
`;

const Inner = styled.div<{
  backgroundColor: string | null | undefined;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${({ backgroundColor }) => css`
    ${backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
  `}
`;

export const RoundedIcon: React.FC<RoundedIconProps> = ({
  children,
  borderColor,
  backgroundColor,
  iconColor,
  addDropShadow = false,
  addWhiteBottom = false,
}) => {
  return (
    <Wrapper
      borderColor={borderColor}
      iconColor={iconColor}
      addDropShadow={addDropShadow}
      addWhiteBottom={addWhiteBottom}
    >
      <Inner backgroundColor={backgroundColor}>{children}</Inner>
    </Wrapper>
  );
};
