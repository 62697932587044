import React from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr 2fr 150px;

    ${theme.breakpoints.down('sm')} {
      display: block;
    }
  `}
`;

export const SwipeableContentContainer: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
