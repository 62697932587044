import { fabric } from 'fabric';

function getInitialCanvasOptions(): fabric.ICanvasOptions {
  return {
    isDrawingMode: false,
    freeDrawingCursor: 'pointer',
    selection: false,
    stopContextMenu: true,
    backgroundColor: 'white',
    allowTouchScrolling: false,
  };
}

export const getFabricCanvasInstance = (
  canvasEl: HTMLCanvasElement,
  _canvasOptions?: fabric.ICanvasOptions
): fabric.Canvas => {
  const canvasOptions = {
    ...getInitialCanvasOptions(),
    ...(_canvasOptions || {}),
  };

  return new fabric.Canvas(canvasEl, canvasOptions);
};
