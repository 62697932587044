import React from 'react';
import { BaseBottomToolbar } from './BaseBottomToolbar';
import { ToolbarFlexContainer, FlexBlock } from './shared/BottomToolbar.styles';

interface Props {
  onDone: () => void;
  onDelete: () => void;
  show: boolean;
}

export const ImageToolbar: React.FC<Props> = ({ onDone, onDelete, show }) => {
  return (
    <ToolbarFlexContainer>
      <FlexBlock />
      <BaseBottomToolbar show={show} onDone={onDone} onDelete={onDelete} />
    </ToolbarFlexContainer>
  );
};
