import styled from 'styled-components/macro';

export const FlexBlock = styled.div`
  flex: 1 auto;
  position: relative;
`;

export const ToolbarFlexContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  button {
    color: ${({ theme }) => theme.palette.grey[500]};
  }
`;

export const ToolbarWrapper = styled.aside<{ show: boolean }>`
  ${({ theme }) => theme.mixins.noVisibleScrollbar};

  transition: transform 500ms ease-in-out;
  transform: translateY(${(props) => (props.show ? '' : '100%')});

  position: relative;
  display: flex;
  flex-direction: row;
  flex: none;

  width: 100%;
  overflow: scroll;
  z-index: 10;
  bottom: 0;

  background-color: ${({ theme }) => theme.palette.grey[50]};
`;

export const Right = styled.div`
  display: flex;
  right: 0;
  margin: 0 ${({ theme }) => theme.spacing(2)}px 0 auto;
`;

export const Left = styled.div`
  ${({ theme }) => theme.mixins.noVisibleScrollbar};

  display: flex;
  width: 100%;

  overflow: scroll;
`;
