import React from 'react';
import { Link as InternalLink } from 'react-router-dom';
import { Link as ExternalLink } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.span`
  display: inline-block;

  ${({ theme }) => css`
    a {
      position: relative;
      display: flex;
      align-items: center;

      font-weight: 700;

      color: ${theme.palette.text.primary};
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
      }

      &:after {
        content: '';

        position: absolute;
        left: 0;
        bottom: 0;

        width: 0%;
        height: 0.15em;

        background-color: ${theme.palette.text.primary};

        transition: ${theme.transitions.duration.standard}ms
          ${theme.transitions.easing.easeInOut};
      }

      &:hover:after {
        width: 100%;
      }
    }
  `}
`;

interface BaseProps {}

interface ExternalLinkProps extends BaseProps {
  to?: undefined;
  href: string;
}

interface InternalLinkProps extends BaseProps {
  to: string;
  href?: undefined;
}

type LinkProps = InternalLinkProps | ExternalLinkProps;

export const Link: React.FC<LinkProps> = ({ children, href, to }) => {
  if (href) {
    return (
      <Wrapper>
        <ExternalLink href={href} target="_blank">
          {children}
        </ExternalLink>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <InternalLink to={to || '/'}>{children}</InternalLink>
    </Wrapper>
  );
};
