/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

import { Section, Tags, Link } from 'components';
import { viewportContext } from 'utils/contexts';

import bwwAnimation from './assets/bww_animation.mp4';

export const BigWhiteWallOverview = () => {
  const { isMobile } = React.useContext(viewportContext);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useLayoutEffect(() => {
    if (!videoRef.current) {
      console.error('No video player found');
      return;
    }

    videoRef.current.play();
  }, []);

  return (
    <Section fullHeight>
      <Grid container spacing={isMobile ? 2 : 10}>
        <Grid item sm={12} md={5}>
          <Typography variant="h3">Big White Wall</Typography>
          <Typography>
            During 2019{' '}
            <Link href="https://www.bigwhitewall.com/">Big White Wall</Link>{' '}
            moved their legacy social network platform over to a brand new
            tech-stack. I was responsible to implement a high performance,
            responsive ReactJs frontend that was compliant with WCAG AAA, the
            highest accessibility standard available.
          </Typography>

          <Box marginY={2} />

          <Tags
            tags={[
              { title: 'TypeScript' },
              { title: 'ReactJs' },
              { title: 'Accessibility' },
              { title: 'Enterprise SPA' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <video ref={videoRef} width="100%" loop muted>
            <source src={bwwAnimation} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>
      </Grid>
    </Section>
  );
};
