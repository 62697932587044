import {
  ThemeOptions as MuiThemeOptions,
  Theme as MuiTheme,
} from '@material-ui/core';
import { css, FlattenSimpleInterpolation } from 'styled-components/macro';

const BASE_UNIT = 8;

interface CustomTheme {
  mixins: {
    noVisibleScrollbar: FlattenSimpleInterpolation;
  };

  palette: {
    common: { successGreen: string };
  };
}

export type Theme = MuiTheme & CustomTheme;

export const noVisibleScrollbar = css`
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const customTheme: CustomTheme & MuiThemeOptions = {
  spacing: BASE_UNIT,

  palette: {
    text: {
      primary: '#1E1F26',
      secondary: '#888',
    },
    common: {
      black: '#1E1F26',
      successGreen: '#79F789',
    },
    primary: {
      main: '#F7789C',
    },
    secondary: {
      main: '#FCBA67',
    },
  },

  typography: {
    fontFamily: 'roboto, monospace',
    fontSize: 16,

    h1: {
      fontSize: '4.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '2.5rem',
      marginBottom: `${1.5}rem`,
    },
    h4: {
      fontSize: '2rem',
      marginBottom: `${1.5}rem`,
    },
  },

  mixins: {
    noVisibleScrollbar,
  },
};
