import React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
  CssBaseline,
} from '@material-ui/core';

import { customTheme, Theme } from '../helpers/theme/customTheme';
import { ViewportProvider } from './ViewportContext';
import { DrawerProvider } from './DrawerContext';
import { HistoryProvider } from './HistoryContext';

export const Providers: React.FC = ({ children }) => {
  let theme = createMuiTheme(customTheme) as Theme;

  theme = responsiveFontSizes(theme) as Theme;

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HistoryProvider>
          <ViewportProvider>
            <DrawerProvider>{children}</DrawerProvider>
          </ViewportProvider>
        </HistoryProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
