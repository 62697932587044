import React, { ChangeEvent, useState } from 'react';
import { withStyles, Slider } from '@material-ui/core';

import { ToolbarButton } from 'components';
import {
  StyledSliderWrapper,
  styles,
  ShortBorder,
} from './ButtonSlider.styles';

interface ButtonSliderProps {
  onChange?<T>(newValue: number, id?: string | number | T): void;
  onClick?: () => void;

  children?: any;
  classes: { thumb: string | undefined; root: string | undefined };
  active: boolean;
  min?: number;
  max?: number;
  step?: number;
  initialValue?: number;
  id?: string | number;
}

const ButtonSliderWithStyles: React.FC<ButtonSliderProps> = ({
  onChange,
  onClick,
  children,
  classes,
  active,
  min = 0,
  max = 10,
  step = 1,
  initialValue = 0,
  id,
}) => {
  const [value, setValue] = useState(initialValue);

  function handleChange(e: ChangeEvent<{}>, newVal: number | number[]) {
    // The new api for Slider introduces ranges, and will always give us back the above type.
    // We just want to work with number here
    if (Array.isArray(newVal)) {
      console.error('Slider should not have a range');
      return;
    }

    if (onChange) {
      onChange(newVal, id);
    }
    setValue(newVal);
  }

  return (
    <>
      <ToolbarButton onClick={onClick} isActive={active}>
        {children}
      </ToolbarButton>

      <ShortBorder>
        <StyledSliderWrapper active={active}>
          <Slider
            classes={{ thumb: classes.thumb, root: classes.root }}
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={handleChange}
          />
        </StyledSliderWrapper>
      </ShortBorder>
    </>
  );
};

export const ButtonSlider = withStyles(styles)(ButtonSliderWithStyles);
