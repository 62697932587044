import { useRef, useEffect } from 'react';

export function useAfterInit(cb: React.EffectCallback, deps: any[]) {
  const isAfterInit = useRef(false);

  useEffect(() => {
    if (isAfterInit.current) {
      return cb();
    }

    isAfterInit.current = true;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};

    // TODO: update hooks deps properly here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
}
