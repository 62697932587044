/* eslint-disable no-bitwise */
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Typography, Box } from '@material-ui/core';
import { numberSwitch } from 'utils/helpers';

const Wrapper = styled.output`
  width: 100%;
`;

const BarWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;

    width: calc(100% - 4px);
    height: 10px;

    border-radius: 5px;

    background-color: ${theme.palette.grey[300]};
  `}
`;

const Bar = styled.div<{ percentage: number; positive?: boolean }>`
  ${({ theme, percentage, positive }) => css`
    position: absolute;
    z-index: 1;
    transform-origin: left;
    transform: rotateZ(${positive ? 0 : 180}deg)
      rotateX(${positive ? 0 : 180}deg);

    width: ${Math.max(positive ? percentage : ~percentage + 1, 0)}%;
    min-width: 5px;
    height: 10px;

    border-radius: 0px 5px 5px 0;

    background-color: ${theme.palette.primary.main};

    transition: width 400ms ${theme.transitions.easing.easeInOut};
  `}
`;

const DescriptionWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    height: ${theme.spacing(3)}px;
    margin-top: ${theme.spacing(1)}px;
  `}
`;

const Handler = styled.div<{ percentage: number }>`
  ${({ theme, percentage }) => css`
    position: absolute;
    z-index: 2;
    transform: translate(-50%, -3px);
    left: ${50 + percentage}%;

    width: ${theme.spacing(2)}px;
    height: ${theme.spacing(2)}px;

    background-color: ${theme.palette.common.white};
    border: 3px solid ${theme.palette.primary.main};
    border-radius: 50%;

    transition: left 400ms ${theme.transitions.easing.easeInOut};
  `}
`;

const Label = styled.label<{ isActive: boolean }>`
  ${({ isActive, theme }) => css`
    .MuiTypography-body2 {
      font-size: ${isActive ? theme.typography.body2.fontSize : '0.8rem'};

      transition: font-size ${theme.transitions.duration.standard}ms
        ${theme.transitions.easing.easeInOut};
    }
  `}
`;

const YAxis = styled.div`
  ${({ theme }) => css`
    position: absolute;
    z-index: 0;

    height: calc(100% + ${theme.spacing(2)}px);
    margin: -${theme.spacing(1)}px 0;

    border-left: 2px solid ${theme.palette.grey[300]};
  `}
`;

interface SentimentChartProps {
  range: [number, number];
  value: number;
}

export const SentimentChart: React.FC<SentimentChartProps> = ({
  range,
  value,
}) => {
  const percentage = React.useMemo(() => {
    // max = range_max - range_min
    const max = range[1] - range[0];

    // Shift range to [0, 100] and calculate percentage
    return ((value - range[0]) / max) * 100 - 50;
  }, [value, range]);

  const sentiment = React.useMemo<'negative' | 'neutral' | 'positive'>(() => {
    let tmp: 'negative' | 'neutral' | 'positive' = 'neutral';

    numberSwitch(percentage)('lessThan', -20, () => {
      tmp = 'negative';
    })('moreThan', 20, () => {
      tmp = 'positive';
    });

    return tmp;
  }, [percentage]);

  return (
    <Wrapper
      tabIndex={0}
      aria-label={`The calculated sentiment of the above text is ${Math.floor(
        percentage
      )} percent which is seen as ${sentiment}`}
    >
      <Box marginBottom={1}>
        <Typography>Sentiment</Typography>
      </Box>
      <BarWrapper>
        <div>
          <Bar percentage={percentage} positive />
          <Bar percentage={percentage} />
          <YAxis />
          <Handler percentage={percentage} />
        </div>
      </BarWrapper>

      <DescriptionWrapper>
        <Label isActive={sentiment === 'negative'}>
          <Typography variant="body2" color="textSecondary">
            Negative
          </Typography>
        </Label>
        <Label isActive={sentiment === 'neutral'}>
          <Typography variant="body2" color="textSecondary">
            Neutral
          </Typography>
        </Label>
        <Label isActive={sentiment === 'positive'}>
          <Typography variant="body2" color="textSecondary">
            Positive
          </Typography>
        </Label>
      </DescriptionWrapper>
    </Wrapper>
  );
};
