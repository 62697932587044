import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { CanvasContext } from '../shared/canvas.helper';
import FabricCanvasWrapper from '../fabricCanvasWrapper/FabricCanvasWrapper';
import { TextEditorToolbar } from './TextEditorToolbar';
import { MainToolbar } from './MainToolbar';
import { DrawToolbar } from './DrawToolbar';
import { ImageToolbar } from './ImageToolbar';
import { BrushMode } from '../shared/types';

interface Props {
  className?: string;
  fabricInstance: FabricCanvasWrapper;
}

const StyledMainToolbar = styled(MainToolbar)<{ show: boolean }>`
  transition: transform 500ms ease-in-out;
  transform: translateX(${(props) => (props.show ? '' : '-100%')});
`;

const StyledAside = styled.aside`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

export const PaperTools: React.FC<Props> = ({ className, fabricInstance }) => {
  const [canvasState, canvasDispatch] = useContext(CanvasContext);

  function onUploadImage(imgUrl: string) {
    fabricInstance.addImage(imgUrl, (imgObj: fabric.Object) => {
      const selectedObject = fabricInstance.focusOn(imgObj);

      canvasDispatch({
        type: 'setCanvasMode',
        canvasMode: 'IMAGE',
        selectedObject,
      });
    });
  }

  function onUndo() {
    fabricInstance.undoLastAdded();
  }

  function onRedo() {
    fabricInstance.redoFromMemory();
  }

  function onPublish() {
    canvasDispatch({
      type: 'setCanvasMode',
      canvasMode: 'PUBLISHED',
      selectedObject: null,
    });
    fabricInstance.exportPng();
  }

  function onCreateText() {
    const textObj: fabric.Object = fabricInstance.createText();
    const selectedObject: fabric.Object = fabricInstance.focusOn(textObj);
    canvasDispatch({
      type: 'setCanvasMode',
      canvasMode: 'TEXT',
      selectedObject,
    });
  }

  function onDone() {
    fabricInstance.deselectAll();
    fabricInstance.emptyObjectMemory();
    canvasDispatch({
      type: 'setCanvasMode',
      canvasMode: 'MAIN',
      selectedObject: null,
    });
  }

  function onDelete() {
    fabricInstance.deleteObjectsInMemory();
    canvasDispatch({
      type: 'setCanvasMode',
      canvasMode: 'MAIN',
      selectedObject: null,
    });
  }

  function onBrushSizeChange(newSize: number, brushMode?: BrushMode) {
    fabricInstance.setBrushSizeOn(
      brushMode || canvasState._contextBrushMode,
      newSize
    );
  }

  return (
    <StyledAside className={className}>
      <StyledMainToolbar
        show={canvasState.canvasMode === 'MAIN'}
        onCreateText={onCreateText}
        onUploadImage={onUploadImage}
        onPublish={onPublish}
        onUndo={onUndo}
        onRedo={onRedo}
      />

      <TextEditorToolbar
        show={
          canvasState.canvasMode === 'TEXT' ||
          canvasState.canvasMode === 'HIGHLIGHT'
        }
        onDone={onDone}
        onDelete={onDelete}
      />

      <DrawToolbar
        show={
          canvasState.canvasMode === 'DRAW' && canvasState.isDrawing === false
        }
        brushDictionary={fabricInstance.brushDictionary}
        onBrushSizeChange={onBrushSizeChange}
        onDone={onDone}
        onDelete={onDelete}
      />

      <ImageToolbar
        show={canvasState.canvasMode === 'IMAGE'}
        onDone={onDone}
        onDelete={onDelete}
      />
    </StyledAside>
  );
};
