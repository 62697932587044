/* eslint-disable no-param-reassign */
export function setCanvasToFitWrapperSize(
  fabricCanvas: fabric.Canvas,
  wrapperEl: HTMLDivElement,
  canvasWrapperEl: HTMLDivElement,
  exportWidth: number,
  exportHeight: number
) {
  if (fabricCanvas === undefined) {
    return;
  }
  const aspectRatio = exportWidth / exportHeight;

  fabricCanvas.setHeight(exportHeight);
  fabricCanvas.setWidth(exportWidth);

  const boundingRect = wrapperEl.getBoundingClientRect();

  // Scale with css canvases to fit wrapper element
  const arWidth = boundingRect.height * aspectRatio;
  const arHeight = boundingRect.width / aspectRatio;
  if (arHeight < boundingRect.width) {
    const scaleX = boundingRect.width / exportWidth;
    const scaleY = boundingRect.width / aspectRatio / exportHeight;

    canvasWrapperEl.style.transform = `scale(${scaleX}, ${scaleY})`;
    wrapperEl.style.height = `${boundingRect.width / aspectRatio}px`;
  }
  if (arWidth < boundingRect.height) {
    const scaleX = (boundingRect.height * aspectRatio) / exportWidth;
    const scaleY = boundingRect.height / exportHeight;

    canvasWrapperEl.style.transform = `scale(${scaleX}, ${scaleY})`;
    wrapperEl.style.width = `${boundingRect.height * aspectRatio}px`;
  }
}
/* eslint-enable no-param-reassign */
