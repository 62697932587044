import React from 'react';

export const useToggleState = (initialState: boolean) => {
  const [state, setState] = React.useState(initialState);

  const toggleState = React.useCallback(() => {
    setState(!state);
  }, [state]);

  return [state, toggleState] as [boolean, () => void];
};
