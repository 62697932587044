import * as React from 'react';
import { ThemeContext } from 'styled-components/macro';
import { Theme as MuiTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

interface Context {
  useMediaQuery: typeof useMediaQuery;
  breakpoints: MuiTheme['breakpoints'];
  isMobile: boolean;
  useBreakpoint: (breakpoint: Breakpoint) => { up: boolean; down: boolean };
}

export const viewportContext = React.createContext<Context>(
  ({} as unknown) as Context // we cast as any here because this context should always be used with a provider.
);

const { Provider } = viewportContext;

export const ViewportProvider: React.FC = ({ children }) => {
  const theme = React.useContext(ThemeContext);

  function useBreakpoint(breakpoint: Breakpoint) {
    const down = useMediaQuery(theme.breakpoints.down(breakpoint));
    const up = useMediaQuery(theme.breakpoints.up(breakpoint));

    return { up, down };
  }

  const useMediaQueryReady = useMediaQuery('@media (min-width:0px)');
  React.useEffect(() => {
    console.info('useMediaQueryReady', useMediaQueryReady);
  }, [useMediaQueryReady]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const ctxValue = {
    breakpoints: theme.breakpoints,
    isMobile,
    useBreakpoint,
    useMediaQuery,
  };

  return (
    <Provider value={ctxValue}>{useMediaQueryReady ? children : null}</Provider>
  );
};
