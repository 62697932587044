import styled, { css } from 'styled-components/macro';

interface SectionProps {
  fullHeight?: boolean;
}

export const Section = styled.section<SectionProps>`
  ${({ theme, fullHeight }) => css`
    width: 100%;
    padding: ${theme.spacing(8)}px 0;

    ${fullHeight &&
    css`
      min-height: 100vh;
    `}
  `}
`;
