import React from 'react';
import { viewportContext } from 'utils/contexts';
import { Grid, Typography, Box, TextField, Card } from '@material-ui/core';
import { cleanData, aggressiveTokenizerEn, porterStemmer } from 'simple-nlp';
import {
  SentimentAnalyzer,
  englishNegations,
  englishPatternVocabulary,
} from 'simple-nlp-sentiment';

import {
  Section,
  Tags,
  InteractiveContentBadge,
  Link,
  SentimentChart,
} from 'components';

const DEFAULT_TEXT_VALUE =
  'Love and hate are two words that will affect the sentiment.';

const englishSentimentAnalyzer = new SentimentAnalyzer({
  type: 'pattern',
  negations: englishNegations,
  vocabulary: englishPatternVocabulary,
});
englishSentimentAnalyzer.stemVocabulary(porterStemmer);

function getSentimentScore(text: string) {
  const bagOfWords = cleanData({
    text,
    tokenizer: aggressiveTokenizerEn,
    stemmer: porterStemmer,
  });

  const sentiment = englishSentimentAnalyzer.getSentiment(bagOfWords, {
    denominator: 'nrOfHits',
  });

  return sentiment.score;
}

export const SimpleNlpOverview = () => {
  const { isMobile } = React.useContext(viewportContext);
  const [sentimentScore, setSentimentScore] = React.useState(
    getSentimentScore(DEFAULT_TEXT_VALUE)
  );

  const onTextChange = React.useCallback((e) => {
    const score = getSentimentScore(e.target.value);

    setSentimentScore(score || 0);
  }, []);

  return (
    <Section fullHeight>
      <Grid container spacing={isMobile ? 2 : 10}>
        <Grid item sm={12} md={5}>
          <Typography variant="h3">Natural Language Processing</Typography>
          <Typography>
            To process any text content generated by the users can be a very
            good insight into their behavior and opinion. To automate this
            process allows a broader live overview. But many of the available
            JavaScript packages on NPM are large, bulky and just not up to date
            with the current build standard of modern JavaScript. Therefore, I
            decided to build my own package that can analyze text (
            <Link href="https://github.com/vleandersson/simple-nlp">
              Simple-NLP
            </Link>{' '}
            still in beta), ready for CommonJS, AMD and UMD.
          </Typography>

          <Box marginY={2} />

          <Tags
            tags={[
              { title: 'TypeScript' },
              { title: 'Natural Language Processing (NLP)' },
              { title: 'ES6' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <InteractiveContentBadge anchorOrigin={{ horizontal: 'right' }}>
            <Card>
              <Box padding={4} paddingTop={6}>
                <Typography variant="h4">Calculate sentiment</Typography>
                <Typography>
                  Write an english sentence and get its sentiment.
                </Typography>

                <Box paddingTop={3}>
                  <TextField
                    defaultValue={DEFAULT_TEXT_VALUE}
                    fullWidth
                    label="Tell us something"
                    multiline
                    onChange={onTextChange}
                    rows={3}
                    variant="outlined"
                  />
                </Box>

                <Box paddingY={4}>
                  <SentimentChart range={[-1, 1]} value={sentimentScore} />
                </Box>
              </Box>
            </Card>
          </InteractiveContentBadge>
        </Grid>
      </Grid>
    </Section>
  );
};
