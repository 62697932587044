import React from 'react';
import { Badge, BadgeOrigin } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div`
  ${({ theme }) => css`
    .MuiBadge {
      &-root {
        display: block;
      }
    }
  `}
`;

const StyledBadge = styled(Badge)`
  ${({ theme }) => css`
    .MuiBadge {
      &-root {
        display: relative;
      }

      &-badge {
        width: 75px;
        height: 75px;

        z-index: ${theme.zIndex.appBar};

        background-color: ${theme.palette.common.black};
        color: ${theme.palette.common.white};
        border-radius: 100%;
      }

      &-anchorOriginTopLeftRectangle {
        transform: scale(1) translate(-25%, -25%);
      }

      &-anchorOriginTopRightRectangle {
        transform: scale(1) translate(25%, -25%);
      }
    }
  `}
`;

interface InteractiveContentBadgeProps {
  anchorOrigin?: {
    horizontal?: BadgeOrigin['horizontal'];
    vertical?: BadgeOrigin['vertical'];
  };
}

export const InteractiveContentBadge: React.FC<InteractiveContentBadgeProps> = ({
  anchorOrigin,
  children,
}) => {
  const _anchorOrigin: BadgeOrigin = {
    ...{ horizontal: 'left', vertical: 'top' },
    ...anchorOrigin,
  };

  return (
    <Wrapper>
      <StyledBadge anchorOrigin={_anchorOrigin} badgeContent="Try me">
        {children}
      </StyledBadge>
    </Wrapper>
  );
};
