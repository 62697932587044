import React from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: inline-table;

    grid-column: 5 / last-col;
    grid-row: 2 / last-row;

    padding: ${theme.spacing(6)}px;

    color: ${theme.palette.getContrastText(theme.palette.common.black)};
    background-color: ${theme.palette.common.black};

    ${theme.breakpoints.down('lg')} {
      grid-column: 3 / last-col;
      /* grid-row: 2 / last-line; */
    }
  `}
`;

export const SwipeableContentRight: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
