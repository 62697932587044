import React from 'react';
import styled, { css } from 'styled-components/macro';
import { IconButton, Portal, Paper, Slide, Backdrop } from '@material-ui/core';
import { rgba } from 'polished';

import { HamburgerIcon, Navigation } from 'components';
import { viewportContext } from 'utils/contexts';
import { useToggleState } from 'utils/hooks';

const Wrapper = styled.div`
  ${({ theme }) => css`
    position: sticky;
    z-index: ${theme.zIndex.modal};
    top: 0;

    display: flex;
    align-items: center;

    width: 100%;
    min-height: 75px;
    background-color: ${rgba(theme.palette.common.white, 0.9)};
    border-bottom: solid 1px ${theme.palette.grey[100]};

    ${theme.breakpoints.down('sm')} {
      min-height: 75px;
    }
  `}
`;

const StyledBackdrop = styled(Backdrop)`
  ${({ theme }) => css`
    &&& {
      z-index: ${theme.zIndex.modal - 1};
    }
  `}
`;

const StyledPaper = styled(Paper)`
  ${({ theme }) => css`
    width: 100%;
    height: 75vh;
  `}
`;

const PaperInner = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    padding: ${theme.spacing(6)}px ${theme.spacing(2)}px;
  `}
`;

// const ContactMeWrapper = styled.div`
//   ${({ theme }) => css`
//     padding: ${theme.spacing(4)}px 0;

//     ${theme.breakpoints.up('sm')} {
//       position: absolute;
//       right: 0;

//       padding: 0 ${theme.spacing(4)}px;
//     }
//   `}
// `;

export const Navbar = () => {
  const { isMobile } = React.useContext(viewportContext);

  const [
    portalContainer,
    setPortalContainer,
  ] = React.useState<HTMLDivElement | null>(null);
  const [mobileMenuOpen, toggleMobileMenuOpen] = useToggleState(false);
  const navRef = React.useRef<HTMLDivElement>(null);
  // const { drawerDispatch } = React.useContext(drawerContext);

  React.useLayoutEffect(() => {
    setPortalContainer(navRef.current);
  }, []);

  React.useEffect(() => {
    // Make sure the scroll for background content is locked when menu is open
    document.body.style.overflow = mobileMenuOpen ? 'hidden' : 'visible';
  }, [mobileMenuOpen]);

  // const onContactMeClick = React.useCallback(() => {
  //   drawerDispatch({ type: 'CONTACT_ME', open: true });
  // }, [drawerDispatch]);

  return (
    <>
      <Wrapper>
        {isMobile && (
          <IconButton onClick={toggleMobileMenuOpen}>
            <HamburgerIcon isActive={mobileMenuOpen} />
          </IconButton>
        )}
        <Portal disablePortal={!isMobile} container={portalContainer}>
          <Navigation />

          {/* <ContactMeWrapper>
            <Button onClick={onContactMeClick} variant="outlined">
              Contact Me
            </Button>
          </ContactMeWrapper> */}
        </Portal>
      </Wrapper>

      {isMobile && (
        <StyledBackdrop open={mobileMenuOpen} transitionDuration={250}>
          <Slide direction="down" timeout={250} in={mobileMenuOpen}>
            <StyledPaper>
              <PaperInner ref={navRef} />
            </StyledPaper>
          </Slide>
        </StyledBackdrop>
      )}
    </>
  );
};
