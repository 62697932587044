import React from 'react';
import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
} from '@material-ui/core';

interface DrawerProps extends MuiDrawerProps {}

export const Drawer: React.FC<DrawerProps> = (props) => {
  return <MuiDrawer {...props} />;
};
