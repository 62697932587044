import React from 'react';
import {
  drawerReducer,
  initialDrawerState,
  DrawerReducerState,
  DrawerAction,
} from 'utils/reducers';

interface Context {
  drawerState: DrawerReducerState;
  drawerDispatch: React.Dispatch<DrawerAction>;
}

export const drawerContext = React.createContext<Context>(
  ({} as unknown) as Context
);

const { Provider } = drawerContext;

export const DrawerProvider: React.FC = ({ children }) => {
  const [drawerState, drawerDispatch] = React.useReducer(
    drawerReducer,
    initialDrawerState
  );

  return (
    <Provider value={{ drawerState, drawerDispatch }}>{children}</Provider>
  );
};
