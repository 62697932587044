import { AlgorithmBase } from '..';

const codeSummary = `const array = [5, -2, 3, 1, 8, 5, 10, -5]; 
const result = quickSort(array); 
// result = [-5, -2, 1, 3, 5, 5, 8, 10]`;

const code = `function quickSort(arr) {
  // low is start index, high is end index
  return recursiveQuickSort([...arr], 0, arr.length - 1);
}

function recursiveQuickSort(arr, low, high) {
  console.log(low, high);
  if (low < high) {
    const pivot = partitioning(arr, low, high);

    recursiveQuickSort(arr, low, pivot - 1); // Before pivot
    recursiveQuickSort(arr, pivot + 1, high); // After pivot
  }
  return arr;
}

function partitioning(arr, low, high) {
  // Pick last element as pivot
  const pivot = arr[high];

  let i = low - 1;

  for (let j = low; j <= high - 1; j++) {
    // If current element is smaller than the pivot
    if (arr[j] < pivot) {
      i++; // increment index of smaller element

      // swap arr[i] and arr[j]
      swapElements(arr, i, j);
    }
  }

  // swap arr[i + 1] and arr[high])
  swapElements(arr, i + 1, high);

  return i + 1;
}

function swapElements(arr, from, to) {
  const p1 = arr[from];
  const p2 = arr[to];

  arr[from] = p2;
  arr[to] = p1;
}`;

export const quickSort: AlgorithmBase = {
  id: 'quick-sort',
  name: 'Quick Sort',
  difficulty: 'easy',
  problemDomain: 'sort',
  code,
  codeSummary,
  timeComplexityDomain: 'P',
  timeComplexity: 'O(n log(n))',
  spaceComplexity: 'O(n)',
  description:
    'A common sorting algorithm that performs well on randomly sorted sets, but badly on sorted or partly sorted sets.',
};
