import React from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

interface Context {
  history: History<History.PoorMansUnknown>;
  previousLocation: string | null;
}

export const historyContext = React.createContext<Context>(
  ({} as unknown) as Context
);

const { Provider } = historyContext;

export const HistoryProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [previousLocation, setPreviousLocation] = React.useState<string | null>(
    null
  );
  const [currentLocation, setCurrentLocation] = React.useState(
    history.location.pathname
  );

  React.useEffect(() => {
    history.listen((h) => {
      setPreviousLocation(currentLocation);
      setCurrentLocation(h.pathname);
    });
  });

  return <Provider value={{ history, previousLocation }}>{children}</Provider>;
};
