import * as React from 'react';
import cuid from 'cuid';
import {
  Field,
  FieldProps,
  ErrorMessage,
  FormikHandlers,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';

import { FormError } from '../FormError/FormError';

interface ExtendedFieldProps<TValues = any, TValue = any> {
  // This idea here is that we have a set of methods that mirror those on the
  // form api, which are pre-mapped to this field's name.
  fieldApi: {
    setFieldValue: (value: TValue) => void;
    setFieldTouched: (touched?: boolean) => void;
  };
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: TValue;
    name: string;
    valid?: boolean;
    describedBy?: string;
  };
  form: FormikProps<TValues>;
}

interface FormikFieldWrapperProps<TValues = any, TValue = any> {
  name: string;
  validationSchema?: Yup.MixedSchema;
  validationFunc?: (value: any) => string[] | null | undefined;
  render: (fieldProps: ExtendedFieldProps<TValues, TValue>) => JSX.Element;
  renderError?: ((error: JSX.Element) => JSX.Element) | false;
}

export const FormikFieldWrapper = <
  // This is no perfect - I was hoping to make this `TValues` and `TName` where
  // `TName extends keyof TValues` but I ran into a number of TS issues with the
  // default types for the generics - if we have time I'd like to pick this up
  // again. But this gives us 80-90% of what I was trying to achieve.
  TValues extends {} = any,
  TValue extends any = any
>({
  name,
  validationSchema,
  validationFunc,
  render,
  renderError = (el) => el,
}: FormikFieldWrapperProps<TValues, TValue>) => {
  const elementId = React.useMemo(() => cuid(), []);

  return (
    <Field
      key={elementId}
      name={name}
      validate={(value: TValue) => {
        if (validationSchema) {
          try {
            validationSchema.validateSync(value);
          } catch (e) {
            return e.errors[0];
          }
        }

        if (validationFunc) {
          const errors = validationFunc(value);
          if (errors && errors.length) {
            return errors[0];
          }
        }

        return null;
      }}
    >
      {(fieldProps: FieldProps) => (
        <>
          {render({
            ...fieldProps,
            field: {
              ...fieldProps.field,
              // valid: !(name in fieldProps.form.errors),
            },
            fieldApi: {
              setFieldValue: (value) =>
                fieldProps.form.setFieldValue(name, value),
              setFieldTouched: (touched = true) =>
                fieldProps.form.setFieldTouched(name, touched),
            },
          })}
          {renderError &&
            renderError(
              <ErrorMessage
                name={name}
                render={(errorMessage) => (
                  <FormError elementId={elementId}>{errorMessage}</FormError>
                )}
              />
            )}
        </>
      )}
    </Field>
  );
};
