import React from 'react';
import styled, { ThemeContext, css } from 'styled-components/macro';

import {
  SwipeableContent,
  SwipeableContentContainer,
  SwipeableContentLeft,
  SwipeableContentRight,
  Quote,
  Section,
} from 'components';
import { Typography, Box } from '@material-ui/core';

import decernoLogoUrl from './assets/decernoLogo.png';

const NameWrapper = styled.div`
  .MuiTypography-body1 {
    font-weight: bold;
  }
`;

const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    img {
      height: ${theme.spacing(6)}px;
    }
  `}
`;

interface HeaderProps {
  name: string;
  title: string;
  imageUrl: string;
}

const Header: React.FC<HeaderProps> = ({ name, title, imageUrl }) => {
  return (
    <HeaderWrapper>
      <div>
        <Name>{name}</Name>
        <JobTitle>{title}</JobTitle>
      </div>
      <img src={imageUrl} alt={`Logo for ${title}`} />
    </HeaderWrapper>
  );
};

const Name: React.FC = ({ children }) => {
  return (
    <NameWrapper>
      <Typography>{children}</Typography>
    </NameWrapper>
  );
};

const JobTitle: React.FC = ({ children }) => {
  const theme = React.useContext(ThemeContext);

  return (
    <Box marginBottom={4} color={theme.palette.grey[500]}>
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

export const Testimonials = () => {
  return (
    <Section fullHeight>
      <SwipeableContent>
        <SwipeableContentContainer>
          <SwipeableContentLeft>
            <Quote>
              I considered Viktor as one of the best members of our team
            </Quote>
          </SwipeableContentLeft>
          <SwipeableContentRight>
            <Header
              name="Philip Svensson"
              title="System Developer at Decerno AB"
              imageUrl={decernoLogoUrl}
            />
            <blockquote>
              <Typography>
                &ldquo;I had the pleasure of being on the same team and working
                together with Viktor for about a year at Decerno. His positivism
                and anything-is-possible attitude really influenced our team.
                Viktor works well with everyone and you can count on him when it
                comes to getting things done. He does this with great knowledge
                and especially with a hunger for learning more and coming up
                with the right approach. During the time we worked together
                Viktor never stopped trying to learn and develop his skill in
                whatever was needed for a certain task. He quickly became the
                go-to-guy when it came to front-end development, and this was
                not only because he was a skilled front-end programmer, people
                notice that Viktor is really good and pedagogical when it comes
                to leading the way forward.
              </Typography>
              <br />
              <Typography>
                All in all, during a whole year I considered Viktor as one of
                the best members of our team and he consistently delivered
                better results than expected.&rdquo;
              </Typography>
            </blockquote>
          </SwipeableContentRight>
        </SwipeableContentContainer>
      </SwipeableContent>
    </Section>
  );
};
