import React from 'react';
import styled, { css } from 'styled-components/macro';
import { TextField, Grid } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Button, FormikFieldWrapper } from 'components';

const Wrapper = styled.div`
  ${({ theme }) => css`
    min-width: 300px;
    padding: ${theme.spacing(2)}px;

    .MuiFormControl {
      &-root {
        width: 100%;
      }
    }
  `}
`;

interface InitialValues {
  email: string;
  name: string;
  phoneNumber: string;
}

export interface Payload extends InitialValues {}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string().required('Required'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .notRequired(),
});

const initialValues: InitialValues = { email: '', name: '', phoneNumber: '' };

interface ContactFormProps {
  onSubmit: (payload: Payload) => Promise<unknown>;
}

export const ContactForm: React.FC<ContactFormProps> = ({ onSubmit }) => {
  return (
    <Wrapper>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(payload, actions) => {
          onSubmit(payload).then(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {({ isSubmitting }: FormikProps<unknown>) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikFieldWrapper
                  name="email"
                  render={({ field }) => (
                    <TextField
                      placeholder="your@email.com"
                      label="Email"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikFieldWrapper
                  name="name"
                  render={({ field }) => (
                    <TextField placeholder="" label="Name" {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikFieldWrapper
                  name="phoneNumber"
                  render={({ field }) => (
                    <TextField
                      placeholder="+44..."
                      label="Phone Number"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  loading={isSubmitting}
                  disabled={false}
                  type="submit"
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};
