import React from 'react';

import {
  CanvasDrawingOverview,
  BigWhiteWallOverview,
  SimpleNlpOverview,
} from '..';

export const SelectedWork = () => {
  return (
    <>
      <CanvasDrawingOverview />
      <BigWhiteWallOverview />
      <SimpleNlpOverview />
    </>
  );
};
