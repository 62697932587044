import styled from 'styled-components/macro';

export const StyledSliderWrapper = styled.span<{ active: boolean }>`
  overflow: hidden;

  width: ${(props) => (props.active ? '220px' : '0')};
  margin: -${({ theme }) => theme.spacing(2)}px 0;
  padding: ${(props) => (props.active ? '0 20px' : '0')};

  transition: width 250ms ease-in-out, padding 250ms ease-in-out;
`;

export const styles = {
  root: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  thumb: {
    width: '22px',
    height: '22px',
    marginTop: '-10px',
  },
};

export const ShortBorder = styled.span`
  display: flex;
  border-right: 2px solid #e6e6e6;
  margin: ${({ theme }) => theme.spacing(1)}px -2px;
`;
