import React from 'react';

import { Content } from 'components';
import { Algorithm } from 'views';
import { useParams } from 'react-router-dom';
import { algorithms } from 'models';

export const AlgorithmScreen = () => {
  const { id } = useParams();

  const selectedAlgorithm = algorithms.find((a) => a.id === id);

  if (!selectedAlgorithm) {
    // TODO: Make an error state
    return null;
  }

  return (
    <Content title={selectedAlgorithm.name} showGoBack>
      <Algorithm {...selectedAlgorithm} />
    </Content>
  );
};
