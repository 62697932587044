import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Container, Typography, Box } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import { Link } from 'components';
import { historyContext } from 'utils/contexts';
import { routes } from 'Routes';

const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    margin-top: ${theme.spacing(7)}px;
  `}
`;

interface ContentProps {
  title?: string;
  showGoBack?: boolean;
}

export const Content: React.FC<ContentProps> = ({
  children,
  title,
  showGoBack = false,
}) => {
  const { previousLocation } = React.useContext(historyContext);

  const titleElement = React.useMemo(() => {
    if (title) {
      return (
        <>
          {showGoBack && (
            <ButtonWrapper>
              <Link to={routes.algorithmLibrary.build()}>
                <ChevronLeft />
                {previousLocation === routes.algorithmLibrary.template
                  ? 'Go back'
                  : 'To algorithms'}
              </Link>
            </ButtonWrapper>
          )}
          <Box paddingTop={18} marginBottom={6}>
            <Typography variant="h1">{title}</Typography>
          </Box>
        </>
      );
    }

    return null;
  }, [title, showGoBack, previousLocation]);

  return (
    <Container>
      {titleElement}
      {children}
    </Container>
  );
};
