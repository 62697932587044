import React from 'react';
import { FormatQuote } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
`;

const QuoteIconWrapper = styled.q`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};

    .MuiSvgIcon {
      &-root {
        width: 3em;
        height: 3em;

        transform: rotate(180deg);
      }
    }
  `}
`;

export const Quote: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <QuoteIconWrapper>
        <FormatQuote />
      </QuoteIconWrapper>

      <Typography variant="h4">{children}</Typography>
    </Wrapper>
  );
};
