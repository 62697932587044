import { fabric } from 'fabric';
import { CanvasAction } from '../../shared/types';
import { defaultTextboxOptions } from '../../shared/defaults.helper';

export const _focusOn = (fabricCanvas: fabric.Canvas, obj: fabric.Object) => {
  fabricCanvas
    .discardActiveObject()
    .setActiveObject(obj)
    .renderAll();
};

export const focusOnLastAdded = (
  fabricCanvas: fabric.Canvas,
  cbSetSelectedObject: (canvasAction: CanvasAction) => void
) => {
  const canvasObjList = fabricCanvas.getObjects();
  const lastAddedObj = canvasObjList[canvasObjList.length - 1];

  _focusOn(fabricCanvas, lastAddedObj);

  cbSetSelectedObject({
    type: 'setSelectedObject',
    selectedObject: lastAddedObj,
  });
};

export const _addImageObject = (
  fabricCanvas: fabric.Canvas,
  imgUrl: string,
  cb: (imageObject: fabric.Object) => void
): void => {
  fabric.Image.fromURL(imgUrl, img => {
    const imageObject = img.scaleToWidth(fabricCanvas.getWidth());
    fabricCanvas.add(imageObject).centerObject(imageObject);

    cb(imageObject);
  });
};

/* eslint-disable no-param-reassign */
export const _positionHiddenTextarea = (objList: fabric.Object[]): void => {
  const textboxList = objList.filter(
    obj => obj.type && ['text', 'textbox'].includes(obj.type)
  ) as fabric.Textbox[];

  textboxList.forEach(textbox => {
    if (textbox.hiddenTextarea) {
      textbox.hiddenTextarea.style.top = '0';
      textbox.hiddenTextarea.style.left = '0';
    }
  });
};
/* eslint-enable no-param-reassign */

export const _createText = (fabricCanvas: fabric.Canvas): fabric.Textbox => {
  const canvasPadding = 50;

  const textObj = new fabric.Textbox('Write me', {
    ...defaultTextboxOptions,
    width: fabricCanvas.getWidth() - canvasPadding * 2,
  });

  fabricCanvas.add(textObj).centerObject(textObj);

  textObj.enterEditing();
  textObj.selectAll();

  return textObj;
};

export const _deselectAll = (fabricCanvas: fabric.Canvas) => {
  fabricCanvas.discardActiveObject();
  fabricCanvas.renderAll();
};
