import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Done } from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

interface Props {
  className?: string;
  color: string;
  isSelected: boolean;
  onClick: (color: string) => void;
}

const StyledColorSwatch = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    position: relative;
    background-color: ${color};
    flex-shrink: 0;
    height: 28px;
    width: 28px;
    margin: ${theme.spacing(1)}px ${theme.spacing(2)}px;
    border: 1px solid ${theme.palette.common.white};
    border-radius: 28px;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.2);

    :hover {
      cursor: pointer;
    }
  `};
`;

const SwatchWrapper = styled.a`
  display: contents;
`;

const StyledDone =
  styled(Done)`
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  ` as React.ComponentType<SvgIconProps>;

export const ColorSwatch: React.FC<Props> = ({
  color,
  onClick,
  isSelected,
}) => {
  return (
    <SwatchWrapper onClick={() => onClick(color)}>
      <StyledColorSwatch color={color}>
        {isSelected ? <StyledDone /> : null}
      </StyledColorSwatch>
    </SwatchWrapper>
  );
};
