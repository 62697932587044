export const availableColors: string[] = [
  '#F54D3D',
  '#FFAB00',
  '#FFEA00',
  '#81C784',
  '#64B5F6',
  '#1976D2',
  '#7C4DFF',
  '#424242',
  '#FFFFFF',
];
