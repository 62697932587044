import React, { useContext, ReactElement } from 'react';

import { BaseBottomToolbar } from './BaseBottomToolbar';
import { ColorSwatchLibrary } from './ColorSwatchLibrary';
import {
  ToolbarFlexContainer,
  FlexBlock,
  Left,
} from './shared/BottomToolbar.styles';
import { CanvasContext } from '../shared/canvas.helper';
import { BwwFontSize, BwwTextHighlight, ToolbarButton } from '../..';
import {
  availableFontFamilies,
  availableFontSizes,
  availableTextAlign,
} from '../shared/typography.helper';
import { availableColors } from '../shared/color.helper';
import {
  alignIconDictionary,
  FontFamilyButton,
  StyledHighlightButton,
} from './TextEditorToolbar.styles';

interface TextEditorToolbarProps {
  onDone: () => void;
  onDelete: () => void;
  show: boolean;
}

export const TextEditorToolbar: React.FC<TextEditorToolbarProps> = ({
  onDone,
  onDelete,
  show,
}) => {
  const [canvasState, canvasDispatch] = useContext(CanvasContext);

  function onColorSelect(color: string) {
    const _contextTextHighlight: { _contextTextHighlight?: string } =
      canvasState.canvasMode === 'HIGHLIGHT'
        ? { _contextTextHighlight: color }
        : {};

    canvasDispatch({
      type: 'setContextVars',
      payload: {
        _contextSelectedColor: color,
        ..._contextTextHighlight,
      },
    });
  }

  function toggleFontFamily() {
    let nextIndex: number =
      availableFontFamilies.indexOf(canvasState._contextFontFamily) + 1;
    if (nextIndex >= availableFontFamilies.length) nextIndex = 0;

    const selectedFont =
      canvasState.canvasMode === 'TEXT'
        ? availableFontFamilies[nextIndex]
        : canvasState._contextFontFamily;

    const currentTextColor = (canvasState.selectedObject as fabric.Textbox)
      .fill as string | undefined;

    canvasDispatch({
      type: 'setContextVars',
      payload: {
        canvasMode: 'TEXT',
        _contextFontFamily: selectedFont,
        _contextSelectedColor: currentTextColor,
      },
    });
  }

  function toggleFontSize() {
    let nextIndex: number =
      availableFontSizes.indexOf(canvasState._contextFontSize) + 1;
    if (nextIndex >= availableFontSizes.length) nextIndex = 0;

    const nextFontSize = availableFontSizes[nextIndex];

    canvasDispatch({
      type: 'setContextVars',
      payload: {
        _contextFontSize: nextFontSize,
      },
    });
  }

  function toggleTextHighlight() {
    let highlightColor: string | undefined;

    if (
      canvasState.canvasMode === 'TEXT' &&
      canvasState._contextTextHighlight
    ) {
      highlightColor = canvasState._contextTextHighlight;
    } else {
      highlightColor = canvasState._contextTextHighlight
        ? undefined
        : availableColors[7];
    }

    canvasDispatch({
      type: 'setContextVars',
      payload: {
        canvasMode: 'HIGHLIGHT',
        _contextTextHighlight: highlightColor,
        _contextSelectedColor: highlightColor,
      },
    });
  }

  function toggleTextAlign() {
    let nextIndex: number =
      availableTextAlign.indexOf(canvasState._contextTextAlign) + 1;

    if (nextIndex >= availableTextAlign.length) {
      nextIndex = 0;
    }

    const nextAlign = availableTextAlign[nextIndex];

    canvasDispatch({
      type: 'setContextVars',
      payload: {
        _contextTextAlign: nextAlign,
      },
    });
  }

  function getFormatAlignIcon(): null | ReactElement<any> {
    const svgComponent = alignIconDictionary[canvasState._contextTextAlign];
    if (!svgComponent) return null;

    return React.createElement(svgComponent, {});
  }

  return (
    <ToolbarFlexContainer>
      <FlexBlock>
        <ColorSwatchLibrary
          show={show}
          onColorSelect={onColorSelect}
          selectedColor={
            typeof canvasState._contextSelectedColor === 'string'
              ? canvasState._contextSelectedColor
              : undefined
          }
        />
      </FlexBlock>
      <BaseBottomToolbar show={show} onDelete={onDelete} onDone={onDone}>
        <Left>
          <FontFamilyButton
            fontFamily={canvasState._contextFontFamily}
            onClick={toggleFontFamily}
            isActive={canvasState.canvasMode === 'TEXT'}
          >
            Font
          </FontFamilyButton>
          <ToolbarButton onClick={toggleFontSize}>
            <BwwFontSize />
          </ToolbarButton>
          <StyledHighlightButton
            isActive={canvasState.canvasMode === 'HIGHLIGHT'}
            fillColor={canvasState._contextTextHighlight}
            onClick={toggleTextHighlight}
          >
            <BwwTextHighlight />
          </StyledHighlightButton>
          <ToolbarButton onClick={toggleTextAlign}>
            {getFormatAlignIcon()}
          </ToolbarButton>
        </Left>
      </BaseBottomToolbar>
    </ToolbarFlexContainer>
  );
};
