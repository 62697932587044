import { CanvasState, CanvasAction, CanvasMode, ContextState } from './types';
import { stateDidUpdate, initialContextState } from './state.helper';

function getContextStateModifier(
  currentCanvasMode: CanvasMode,
  nextCanvasMode: CanvasMode,
  selectedObject: CanvasState['selectedObject']
): { selectedObject?: CanvasState['selectedObject'] } | ContextState {
  let stateModifier:
    | { selectedObject?: CanvasState['selectedObject'] }
    | ContextState = currentCanvasMode === 'MAIN' ? { selectedObject } : {};

  if (selectedObject && selectedObject.type === 'textbox') {
    const selected = selectedObject as fabric.Textbox;
    const _contextSelectedColor =
      nextCanvasMode === 'TEXT' ? selected.fill : selected.textBackgroundColor;

    stateModifier = {
      ...stateModifier,
      _contextSelectedColor,
      _contextTextHighlight: selected.textBackgroundColor,
      _contextFontFamily: selected.fontFamily,
      _contextFontSize: selected.fontSize,
      _contextTextAlign: selected.textAlign,
    };
  }

  return stateModifier;
}

function getCanvasModeFromObjectContext(
  currentCanvasMode: CanvasMode,
  selectedObject: CanvasState['selectedObject']
): CanvasMode {
  if (currentCanvasMode === 'MAIN' && !!selectedObject) {
    switch (selectedObject.type) {
      case 'textbox':
        return 'TEXT';
      case 'image':
        return 'IMAGE';
      default:
        return currentCanvasMode;
    }
  }

  return currentCanvasMode;
}

export const canvasReducer = (state: CanvasState, action: CanvasAction) => {
  let nextState: CanvasState;

  switch (action.type) {
    case 'setSelectedObject':
      nextState = { ...state, selectedObject: action.selectedObject };
      return stateDidUpdate(state, nextState) ? nextState : state;

    case 'setCanvasMode': {
      const stateModifier =
        action.canvasMode === 'MAIN' ? {} : initialContextState;

      nextState = {
        ...state,
        ...stateModifier,
        canvasMode: action.canvasMode,
        selectedObject: action.selectedObject,
      };
      return stateDidUpdate(state, nextState) ? nextState : state;
    }

    case 'setBrushMode':
      nextState = {
        ...state,
        _contextBrushMode: action.newMode,
      };
      return stateDidUpdate(state, nextState) ? nextState : state;

    case 'onMouseDown': {
      const nextCanvasMode = getCanvasModeFromObjectContext(
        state.canvasMode,
        action.selectedObject
      );

      nextState = {
        ...state,
        ...getContextStateModifier(
          state.canvasMode,
          nextCanvasMode,
          action.selectedObject
        ),
        isDrawing: state.canvasMode === 'DRAW',
        canvasMode: nextCanvasMode,
      };
      return stateDidUpdate(state, nextState) ? nextState : state;
    }

    case 'onMouseUp':
      nextState = { ...state, isDrawing: false };
      return stateDidUpdate(state, nextState) ? nextState : state;

    case 'onObjectAdded':
      nextState = { ...state, canUndo: true };
      return stateDidUpdate(state, nextState) ? nextState : state;

    case 'onObjectRemoved':
      nextState = {
        ...state,
        canUndo: action.canvasMemory.length !== 0,
      };
      return stateDidUpdate(state, nextState) ? nextState : state;

    case 'redoMemoryUpdate':
      nextState = {
        ...state,
        canRedo: action.redoMemory.length !== 0,
      };
      return stateDidUpdate(state, nextState) ? nextState : state;

    case 'setContextVars':
      nextState = {
        ...state,
        ...action.payload,
      };
      return stateDidUpdate(state, nextState) ? nextState : state;

    default:
      throw new Error('Reducer did not receive any valid action.');
  }
};
