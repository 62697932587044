import React from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

const StyledButton = styled<React.FC<MuiButtonProps>>(({ ...rest }) => (
  <MuiButton {...rest} />
))`
  ${({ theme }) => css`
    &.MuiButton {
      &-root {
        min-height: 48px;
        text-transform: none;
        font-weight: bold;
      }
      &-outlined {
        padding: 10px 20px;

        border-image-slice: 1;
        border-width: 3px;
        border-image-source: linear-gradient(
          45deg,
          ${theme.palette.primary.main} 30%,
          ${theme.palette.secondary.main} 90%
        );
      }

      &-contained {
        background: linear-gradient(
          45deg,
          ${theme.palette.primary.main} 30%,
          ${theme.palette.secondary.main} 90%
        );
        color: ${theme.palette.common.white};
        padding: 10px 30px;
      }
    }
  `}
`;

const IconWrapper = styled.span`
  display: flex;
  margin-left: 5px;
`;

interface ButtonProps extends MuiButtonProps {
  icon?: JSX.Element;
  loading?: boolean;
}

const LoadingSpinner: React.FC = () => {
  return <CircularProgress size={30} color="secondary" />;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  icon,
  loading,
  ...muiProps
}) => {
  return (
    <StyledButton {...muiProps}>
      {loading ? <LoadingSpinner /> : children}
      {Boolean(icon) && <IconWrapper>{icon}</IconWrapper>}
    </StyledButton>
  );
};
