import React from 'react';
import { CanvasState, CanvasAction } from './types';

// Canvas Context
const dummyDefaultValue: [
  CanvasState,
  React.Dispatch<CanvasAction>
] = Object.create(null);

export const CanvasContext = React.createContext<
  [CanvasState, React.Dispatch<CanvasAction>]
>(dummyDefaultValue);
