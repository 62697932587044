import React from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    display: inline-block;

    padding: ${theme.spacing(2)}px;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: ${theme.transitions.duration.short}s;
    transition-timing-function: linear;

    &:hover {
      opacity: 0.7;
    }
  `}
`;

const HamburgerBox = styled.div`
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

const Inner = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    display: block;
    margin-top: -2px;
    top: auto;
    bottom: 0;
    transition-duration: ${theme.transitions.duration.short}s;
    transition-delay: 0.13s;
    transition-timing-function: ${theme.transitions.easing.easeInOut};

    &,
    &:before,
    &:after {
      width: 40px;
      height: 4px;
      background-color: #000;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &:before,
    &:after {
      content: '';
      display: block;
    }
    &:before {
      top: -10px;
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &:after {
      bottom: -10px;
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear;
    }

    ${isActive &&
    css`
      transform: translate3d(0, -10px, 0) rotate(-45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &:after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          opacity 0.1s 0.22s linear;
      }
      &:before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    `}
  `}
`;

interface HamburgerIconProps {
  isActive: boolean;
}

export const HamburgerIcon: React.FC<HamburgerIconProps> = ({ isActive }) => {
  return (
    <Wrapper isActive={isActive}>
      <HamburgerBox>
        <Inner isActive={isActive} />
      </HamburgerBox>
    </Wrapper>
  );
};
