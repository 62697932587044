import React from 'react';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

const StyledChip = styled<React.FC<MuiChipProps & { rounded: boolean }>>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ rounded, ...props }) => <MuiChip {...props} />
)`
  ${({ theme, rounded }) => css`
    min-width: 75px;

    ${!rounded &&
    css`
      border-radius: 0;
    `}

    &.MuiChip {
      &-root {
        cursor: inherit;
      }

      &-colorPrimary {
        color: ${theme.palette.common.white};
      }
    }

    .MuiChip {
      &-label {
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  `}
`;

interface ChipProps extends MuiChipProps {
  rounded?: boolean;
}

export const Chip: React.FC<ChipProps> = ({ rounded = false, ...props }) => {
  return <StyledChip rounded={rounded} {...props} />;
};
