import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { Error as ErrorIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

type Variant = 'hint' | 'error';

interface Props {
  children: React.ReactNode;
  className?: string;
  variant?: Variant;
  elementId?: string;
}

const Wrapper = styled.div<{ variant: Variant }>`
  ${({ theme, variant }) =>
    css`
      display: flex;
      position: relative;
      width: 100%;
      padding: ${theme.spacing(1)}px ${theme.spacing(1)}px;
      margin: ${theme.spacing(1)}px auto;

      box-sizing: border-box;
      flex-wrap: nowrap;

      background-color: ${variant === 'error'
        ? theme.palette.error.main
        : theme.palette.warning.main};
    `}
`;

const TextWrapper = styled.div`
  display: flex;
`;

const StyledText = styled(({ ...rest }) => (
  <Typography aria-live="assertive" role="alert" {...rest} />
))`
  margin: auto;
`;

const StyledErrorIcon = styled(({ ...rest }) => <ErrorIcon {...rest} />)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing(1)}px;
  `}
`;

export const FormError: React.FC<Props> = ({
  children,
  className,
  variant = 'error',
  elementId,
}) => {
  return (
    <Wrapper className={className} variant={variant}>
      {variant === 'error' && <StyledErrorIcon color="error" />}

      <TextWrapper>
        <StyledText id={elementId}>{children}</StyledText>
      </TextWrapper>
    </Wrapper>
  );
};
