import WebFont from 'webfontloader';
import { ContextState } from './types';

export const availableFontFamilies: string[] = [
  'Covered By Your Grace',
  'Merriweather',
  'Pangolin',
  'Space Mono',
];

export const availableFontSizes: number[] = [20, 30, 40, 55];

export const availableTextAlign: ContextState['_contextTextAlign'][] = [
  'center',
  'left',
  'right',
];

export const _loadFonts = () => {
  WebFont.load({
    google: {
      families: availableFontFamilies,
    },
    timeout: 2000,
    events: false,
  });
};
