import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { IconButton } from '@material-ui/core';
import { CanvasContext } from '../shared/canvas.helper';
import { UploadImage } from './UploadImage';
import { BwwFlatBrush, BwwTitle } from '../../Icon/Icon';

export interface MainToolbarProps {
  className?: string;
  onCreateText: () => void;
  onUploadImage: (imgUrl: string) => void;
  onPublish: () => void;
  onUndo: () => void;
  onRedo: () => void;
}

const ToobarWrapper = styled.div<{}>`
  height: 100%;

  button,
  label > span {
    color: ${({ theme }) => theme.palette.grey[500]};
  }

  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  z-index: 10;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.grey[50]};
`;

export const MainToolbar: React.FC<MainToolbarProps> = ({
  className,
  onCreateText,
  onUploadImage,
  onPublish,
  onUndo,
  onRedo,
}) => {
  const [, canvasDispatch] = useContext(CanvasContext);

  function onUseBrush() {
    canvasDispatch({
      type: 'setCanvasMode',
      canvasMode: 'DRAW',
      selectedObject: null,
    });
  }

  return (
    <ToobarWrapper className={className}>
      <IconButton onClick={onCreateText}>
        <BwwTitle />
      </IconButton>
      <IconButton onClick={onUseBrush}>
        <BwwFlatBrush />
      </IconButton>
      <UploadImage onUploadImage={onUploadImage} />
      {/* TODO: Implement the collections functionallity */}
      {/* <IconButton onClick={() => console.log('Add BG from collection')}>
        <Collections />
      </IconButton> */}

      {/* <Divider /> */}
      {/* <IconButton disabled={!canvasState.canUndo} onClick={onUndo}>
        <Undo />
      </IconButton>
      <IconButton disabled={!canvasState.canRedo} onClick={onRedo}>
        <Redo />
      </IconButton> */}
      {/* <IconButton onClick={onPublish}>
        <BwwCheckCircle />
      </IconButton> */}
    </ToobarWrapper>
  );
};
