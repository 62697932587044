import React from 'react';
import { AlgorithmBase } from '..';

const codeSummary = `const array = [5, -2, 3, 1, 8, 5, 10, -5]; 
const result = mergeSort(array);
// result = [-5, -2, 1, 3, 5, 5, 8, 10]`;

const code = `function mergeSort(arr) {
  if (arr.length <= 1) {
    // There are no inversions
    return arr;
  } else {
    const half = Math.floor(arr.length / 2);
    const leftArr = arr.slice(0, half);
    const rightArr = arr.slice(half, arr.length);

    const left = mergeSort(leftArr);
    const right = mergeSort(rightArr);

    return merge(left, right);
  }
}

function merge(A, B) {
  const C = [];
  let pA = 0; // Pointer A
  let pB = 0; // Pointer B

  while (true) {
    if (pA === A.length || pB === B.length) {
      break;
    }

    const a = A[pA];
    const b = B[pB];

    // Append the smaller element to C
    C.push(a > b ? b : a);

    // Advance the current pointer in the list from which the smaller element was selected
    if (a > b) {
      pB++;
    } else {
      pA++;
    }
  }

  // Append the reminding array elements to C
  if (pA !== A.length) {
    C.push(...A.slice(pA, A.lenght));
  }

  if (pB !== B.length) {
    C.push(...B.slice(pB, B.lenght));
  }

  return C;
}`;

export const mergeSort: AlgorithmBase = {
  id: 'merge-sort',
  name: 'Merge Sort',
  difficulty: 'easy',
  problemDomain: 'sort',
  code,
  codeSummary,
  timeComplexityDomain: 'P',
  timeComplexity: 'O(n log(n))',
  description: 'A common sorting algorithm.',
  spaceComplexity: (
    <>
      O(n<sup>2</sup>)
    </>
  ),
};
