import React from 'react';

import { Drawer } from 'components';
import { ContactForm } from 'views';
import { drawerContext } from 'utils/contexts';
import { DrawerAction } from 'utils/reducers';
import { Box } from '@material-ui/core';

export const GlobalDrawers = () => {
  const { drawerState, drawerDispatch } = React.useContext(drawerContext);

  const onCloseDrawer = React.useCallback(
    (type: DrawerAction['type']) => {
      drawerDispatch({ type, open: false });
    },
    [drawerDispatch]
  );

  return (
    <>
      <Drawer
        open={drawerState.contactMe.open}
        onEscapeKeyDown={() => onCloseDrawer('CONTACT_ME')}
        onBackdropClick={() => onCloseDrawer('CONTACT_ME')}
        anchor="right"
      >
        <Box padding={6}>
          <ContactForm />
        </Box>
      </Drawer>
    </>
  );
};
