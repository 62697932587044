import React from 'react';
import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';

import { Chip } from 'components';

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing(2)}px 0;
  `}
`;

interface Tag {
  title: string;
}

interface TagsProps {
  tags: Tag[];
}

export const Tags: React.FC<TagsProps> = ({ tags }) => {
  return (
    <Wrapper>
      <Grid container spacing={1}>
        {tags.map((t) => (
          <Grid key={`${t.title}`} item>
            <Chip label={t.title} />
          </Grid>
        ))}
      </Grid>
    </Wrapper>
  );
};
