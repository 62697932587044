interface DrawerState {
  open: boolean;
}

export interface DrawerReducerState {
  contactMe: DrawerState;
}

export type DrawerAction = { type: 'CONTACT_ME'; open: boolean };

function closeOthers(
  state: DrawerReducerState,
  current: keyof DrawerReducerState
) {
  const stateCopy = { ...state };

  Object.keys(state)
    .filter((k) => k !== current)
    .forEach((key) => {
      stateCopy[key as keyof DrawerReducerState].open = false;
    });

  return stateCopy;
}

export const initialDrawerState = { contactMe: { open: false } };

export function drawerReducer(state: DrawerReducerState, action: DrawerAction) {
  switch (action.type) {
    case 'CONTACT_ME': {
      const restOfState = closeOthers(state, 'contactMe');
      return { ...restOfState, contactMe: { open: action.open } };
    }

    default:
      throw new Error('Reducer failed to find a match');
  }
}
