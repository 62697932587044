import React from 'react';
import { ContactForm as Presentational, Payload } from './ContactForm';

export const ContactForm = () => {
  const onSubmit = React.useCallback((payload: Payload) => {
    // TODO: Remove
    console.info(payload);
    return Promise.resolve();
  }, []);

  return <Presentational onSubmit={onSubmit} />;
};
