import { useAfterInit } from 'utils/hooks/useAfterInit';

import { CanvasState } from './types';
import FabricCanvasWrapper from '../fabricCanvasWrapper/FabricCanvasWrapper';
import {
  onCanvasModeChange,
  onSelectedObjectChange,
  onIsDrawingChange,
  onBrushModeChange,
  onSelectedColorChange,
  onFontFamilyChange,
  onFontSizeChange,
  onTextAlignChange,
  onTextHighlightChange,
} from './state.helper';

export function useCanvasEffect(
  canvasState: CanvasState,
  fabricInstance: FabricCanvasWrapper | null
) {
  useAfterInit(() => {
    if (!fabricInstance) return;
    onCanvasModeChange(fabricInstance, canvasState.canvasMode);
  }, [canvasState.canvasMode]);

  useAfterInit(() => {
    if (!fabricInstance) return;
    onSelectedObjectChange(fabricInstance, canvasState.selectedObject);
  }, [canvasState.selectedObject]);

  useAfterInit(() => {
    if (!fabricInstance) return;
    onIsDrawingChange(fabricInstance, canvasState.isDrawing);
  }, [canvasState.isDrawing]);

  useAfterInit(() => {
    if (!fabricInstance) return;
    onBrushModeChange(
      fabricInstance,
      canvasState._contextBrushMode,
      canvasState._contextSelectedColor
    );
  }, [canvasState._contextBrushMode]);

  useAfterInit(() => {
    if (!fabricInstance) return;
    onSelectedColorChange(
      fabricInstance,
      canvasState.canvasMode,
      canvasState.selectedObject,
      canvasState._contextSelectedColor
    );
  }, [canvasState._contextSelectedColor]);

  useAfterInit(() => {
    if (!fabricInstance) return;
    onFontFamilyChange(
      fabricInstance,
      canvasState.selectedObject,
      canvasState._contextFontFamily
    );
  }, [canvasState._contextFontFamily]);

  useAfterInit(() => {
    if (!fabricInstance) return;
    onFontSizeChange(
      fabricInstance,
      canvasState.selectedObject,
      canvasState._contextFontSize
    );
  }, [canvasState._contextFontSize]);

  useAfterInit(() => {
    if (!fabricInstance) return;
    onTextAlignChange(
      fabricInstance,
      canvasState.selectedObject,
      canvasState._contextTextAlign
    );
  }, [canvasState._contextTextAlign]);

  useAfterInit(() => {
    if (!fabricInstance) return;
    onTextHighlightChange(
      fabricInstance,
      canvasState.selectedObject,
      canvasState._contextTextHighlight
    );
  }, [canvasState._contextTextHighlight]);
}
