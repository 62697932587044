import React from 'react';
import Prism from 'prismjs';
import { Typography, Grid } from '@material-ui/core';
import { AccessTime, AccountTree } from '@material-ui/icons';

import { AlgorithmBase } from 'models';
import { Code, Section, InfoCard } from 'components';

interface AlgorithmProps extends AlgorithmBase {}

export const Algorithm: React.FC<AlgorithmProps> = ({
  codeSummary,
  description,
  code,
  timeComplexity,
  spaceComplexity,
  difficulty,
  problemDomain,
  timeComplexityDomain,
}) => {
  React.useLayoutEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <>
      <Typography paragraph>{description}</Typography>

      <Grid container spacing={2}>
        <Grid item>
          <InfoCard title="Problem domain" info={problemDomain} />
        </Grid>
        <Grid item>
          <InfoCard
            icon={<AccessTime />}
            title="Time Complexity"
            info={timeComplexity}
          />
        </Grid>
        <Grid item>
          <InfoCard title="Complexity Domain" info={timeComplexityDomain} />
        </Grid>
        <Grid item>
          <InfoCard
            icon={<AccountTree />}
            title="Space Complexity"
            info={spaceComplexity}
          />
        </Grid>
        <Grid item>
          <InfoCard title="Difficulty" info={difficulty} />
        </Grid>
      </Grid>

      <Section>
        <Typography variant="h2" gutterBottom>
          Usage
        </Typography>
        <figure>
          <pre>
            <Code>{codeSummary}</Code>
          </pre>
        </figure>
      </Section>

      <Section>
        <Typography variant="h2" gutterBottom>
          The Algorithm
        </Typography>
        <figure>
          <pre>
            <Code>{code}</Code>
          </pre>
        </figure>
      </Section>
    </>
  );
};
