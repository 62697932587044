//Eraser issue https://stackoverflow.com/questions/19311038/fabric-js-eraser-issue-canvas
// Eraser Solution https://jsfiddle.net/Fidel90/x35uou5w/
// http://fabricjs.com/freedrawing

import { fabric } from 'fabric';
import { BrushMode } from '../../shared/types';
import FabricCanvasWrapper from '../FabricCanvasWrapper';
import { availableColors } from '../../shared/color.helper';

/* eslint-disable no-param-reassign */
function createPencil(fabricCanvas: fabric.Canvas) {
  const pencil = new fabric.PencilBrush(fabricCanvas);
  pencil.width = 5;

  return pencil;
}

function createSprayBrush(fabricCanvas: fabric.Canvas) {
  const sprayBrush = new fabric.SprayBrush(fabricCanvas);

  sprayBrush.width = 5;
  sprayBrush.density = 10;
  sprayBrush.dotWidth = 1;
  sprayBrush.randomOpacity = true;

  return sprayBrush;
}

function createPainterlyBrush(fabricCanvas: fabric.Canvas) {
  const painterlyBrush = new fabric.PatternBrush(fabricCanvas);
  painterlyBrush.width = 5;

  painterlyBrush.getPatternSrc = function getPatternSrc(): HTMLCanvasElement {
    const patternCanvas = window.document.createElement('canvas');
    patternCanvas.width = 10;
    patternCanvas.height = 10;
    const ctx = patternCanvas.getContext('2d');

    if (!ctx) {
      return patternCanvas;
    }

    ctx.strokeStyle = this.color;
    ctx.lineWidth = 5;
    ctx.beginPath();
    ctx.moveTo(0, 5);
    ctx.lineTo(10, 5);
    ctx.closePath();
    ctx.stroke();

    return patternCanvas;
  };

  return painterlyBrush;
}

export function _initBrushes(
  fabricCanvas: fabric.Canvas,
  initialBrush: BrushMode
): { [id in BrushMode]: fabric.BrushTypes | undefined } {
  const brushDictionary: {
    [id in BrushMode]: fabric.BrushTypes | undefined
  } = {
    pencil: undefined,
    sprayBrush: undefined,
    painterlyBrush: undefined,
  };

  brushDictionary['pencil'] = createPencil(fabricCanvas);
  brushDictionary['sprayBrush'] = createSprayBrush(fabricCanvas);
  brushDictionary['painterlyBrush'] = createPainterlyBrush(fabricCanvas);

  fabricCanvas.freeDrawingBrush = brushDictionary[initialBrush];
  if (!fabricCanvas.freeDrawingBrush) {
    return brushDictionary;
  }
  fabricCanvas.freeDrawingBrush.color = availableColors[0];

  return brushDictionary;
}

export function _setBrushColor(this: FabricCanvasWrapper, newColor: string) {
  if (!this.fabricCanvas.freeDrawingBrush) {
    return;
  }
  this.fabricCanvas.freeDrawingBrush.color = newColor;
}

export function _setBrushMode(
  this: FabricCanvasWrapper,
  newBrushMode: BrushMode,
  color: string
) {
  this.fabricCanvas.freeDrawingBrush = this.brushDictionary[newBrushMode];
  _setBrushColor.call(this, color);
}
/* eslint-enable no-param-reassign */
