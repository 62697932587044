import React from 'react';

import { HeroBanner, Testimonials, SelectedWork } from 'views';
import { Content } from 'components';

export const PortfolioScreen = () => {
  return (
    <>
      <HeroBanner />
      <Content>
        <SelectedWork />
        <Testimonials />
        {/* <KeyKnowledge /> */}
        {/* <Education /> */}
      </Content>
    </>
  );
};
