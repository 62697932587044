import React from 'react';
import styled, { css } from 'styled-components/macro';
import { availableColors } from '../shared/color.helper';
import { ColorSwatch } from './ColorSwatch';

interface Props {
  className?: any;
  show: boolean;
  selectedColor?: string;
  onColorSelect: (color: string) => void;
}

const StyledAside = styled.aside<{ show: boolean }>`
  ${({ theme, show }) => css`
    ${theme.mixins.noVisibleScrollbar};

    transition: transform 500ms ease-in-out;
    transform: translateX(${show ? '0' : '100%'});

    position: absolute;
    display: flex;
    flex-direction: column;

    right: 0px;
    height: 100%;
    overflow: scroll;
    z-index: 10;
  `};
`;

export const ColorSwatchLibrary: React.FC<Props> = ({
  className,
  onColorSelect,
  selectedColor,
  show,
}) => {
  return (
    <StyledAside show={show} className={className}>
      {availableColors.map((color, i) => (
        <ColorSwatch
          key={i}
          color={color}
          onClick={onColorSelect}
          isSelected={selectedColor === color}
        />
      ))}
    </StyledAside>
  );
};
