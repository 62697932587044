import shallowequal from 'shallowequal';
import { CanvasState, CanvasMode, ContextState } from './types';
import { availableColors } from './color.helper';
import { availableFontFamilies, availableFontSizes } from './typography.helper';
import FabricCanvasWrapper from '../fabricCanvasWrapper/FabricCanvasWrapper';

export const initialContextState: ContextState = {
  _contextSelectedColor: availableColors[0],
  _contextBrushMode: 'pencil',
  _contextFontFamily: availableFontFamilies[0],
  _contextFontSize: availableFontSizes[0],
  _contextTextAlign: 'center',
  _contextTextHighlight: undefined,
};

export const initialState: CanvasState = {
  selectedObject: null,
  canvasMode: 'MAIN',
  brushMode: 'pencil',
  isDrawing: false,
  canUndo: false,
  canRedo: false,
  ...initialContextState,
};

export function onCanvasModeChange(
  fabricInstance: FabricCanvasWrapper,
  canvasMode: CanvasMode
): void {
  fabricInstance.setObjectsIsSelectable(
    canvasMode !== 'DRAW' && canvasMode !== 'PUBLISHED'
  );
  fabricInstance.setDrawingMode(canvasMode === 'DRAW');
}

export function onSelectedObjectChange(
  fabricInstance: FabricCanvasWrapper,
  selectedObject: CanvasState['selectedObject']
) {
  fabricInstance.togglePossibleObjectSelection(selectedObject);
  fabricInstance.addSelectionToMemory([selectedObject]);
}

export function onFontFamilyChange(
  fabricInstance: FabricCanvasWrapper,
  selectedObject: CanvasState['selectedObject'],
  fontFamily: CanvasState['_contextFontFamily']
): void {
  if (selectedObject === null) return;

  fabricInstance.changeFontOn(selectedObject as fabric.Textbox, fontFamily);
}

export function onFontSizeChange(
  fabricInstance: FabricCanvasWrapper,
  selectedObject: CanvasState['selectedObject'],
  fontSize: CanvasState['_contextFontSize']
): void {
  if (selectedObject === null) return;

  fabricInstance.changeFontSizeOn(selectedObject as fabric.Textbox, fontSize);
}

export function onTextAlignChange(
  fabricInstance: FabricCanvasWrapper,
  selectedObject: CanvasState['selectedObject'],
  textAlign: CanvasState['_contextTextAlign']
): void {
  if (selectedObject === null) return;

  fabricInstance.setTextAlignmentOn(
    selectedObject as fabric.Textbox,
    textAlign
  );
}

export function onTextHighlightChange(
  fabricInstance: FabricCanvasWrapper,
  selectedObject: CanvasState['selectedObject'],
  highlightColor: string | undefined
): void {
  if (selectedObject === null) return;
  fabricInstance.setHighlightColorOn(
    selectedObject as fabric.Textbox,
    highlightColor
  );
}

export function onBrushModeChange(
  fabricInstance: FabricCanvasWrapper,
  _contextBrushMode: CanvasState['_contextBrushMode'],
  _contextSelectedColor: CanvasState['_contextSelectedColor']
): void {
  fabricInstance.setBrushMode(
    _contextBrushMode,
    typeof _contextSelectedColor === 'string' ? _contextSelectedColor : ''
  );
}

export function onSelectedColorChange(
  fabricInstance: FabricCanvasWrapper,
  canvasMode: CanvasMode,
  selectedObject: CanvasState['selectedObject'],
  _contextSelectedColor: CanvasState['_contextSelectedColor']
): void {
  if (canvasMode === 'HIGHLIGHT' && selectedObject) {
    fabricInstance.setHighlightColorOn(
      selectedObject as fabric.Textbox,
      typeof _contextSelectedColor === 'string'
        ? _contextSelectedColor
        : undefined
    );
  } else {
    fabricInstance.setColorFor(
      selectedObject,
      typeof _contextSelectedColor === 'string' ? _contextSelectedColor : ''
    );
  }
}

export function onIsDrawingChange(
  fabricInstance: FabricCanvasWrapper,
  isDrawing: boolean
): void {
  if (isDrawing) {
    fabricInstance.cleanRedoMemory();
  }
}

export function stateDidUpdate(
  prevState: CanvasState,
  nextState: CanvasState
): boolean {
  return !shallowequal(prevState, nextState);
}
