import styled from 'styled-components/macro';

export const StyledWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

export const StyledCanvasWrapper = styled.div`
  display: table;
  width: 100%;
  transform-origin: top left;
`;
