import React from 'react';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled.div`
  ${({ theme }) => css`
    grid-column: 1 / span 8;
    grid-row: 1 / span 2;

    max-height: 500px;
    padding: ${theme.spacing(6)}px;

    background-color: ${theme.palette.grey[100]};
  `}
`;

export const SwipeableContentLeft: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
