import { fabric } from 'fabric';

import {
  _createText,
  _addImageObject,
  _deselectAll,
  _focusOn,
  _positionHiddenTextarea,
} from './shared/canvasObject.helper';
import { CanvasAction, CanvasState, BrushMode } from '../shared/types';
import { getFabricCanvasInstance } from './shared/init.helper';

import {
  _initBrushes,
  _setBrushMode,
  _setBrushColor,
} from './shared/freeDrawing.helper';
import { setCanvasToFitWrapperSize } from './shared/dom.helper';
import { _loadFonts } from '../shared/typography.helper';

export default class FabricCanvasWrapper {
  fabricCanvas: fabric.Canvas;

  brushDictionary: { [id in BrushMode]: any };

  selectedObjectMemory: fabric.Object[] = [];

  private wrapperRef: HTMLDivElement;

  private canvasWrapperRef: HTMLDivElement;

  private canvasRef: HTMLCanvasElement;

  private dispatch: React.Dispatch<CanvasAction>;

  private redoMemory: fabric.Object[];

  private exportWidth: number;

  private exportHeight: number;

  private onPublish?: (dataUrl: string) => void;

  constructor(
    _canvasRef: HTMLCanvasElement,
    _canvasWrapperRef: HTMLDivElement,
    _wrapperRef: HTMLDivElement,
    _dispatch: React.Dispatch<CanvasAction>,
    _initialState: CanvasState,
    _exportWidth: number,
    _exportHeight: number,
    _onPublish?: (dataUrl: string) => void,
    _canvasOptions?: fabric.ICanvasOptions
  ) {
    this.wrapperRef = _wrapperRef;
    this.canvasWrapperRef = _canvasWrapperRef;
    this.canvasRef = _canvasRef;
    this.redoMemory = [];
    this.onPublish = _onPublish;
    this.dispatch = _dispatch;
    this.exportHeight = _exportHeight;
    this.exportWidth = _exportWidth;

    this.fabricCanvas = getFabricCanvasInstance(this.canvasRef, _canvasOptions);

    setCanvasToFitWrapperSize(
      this.fabricCanvas,
      this.wrapperRef,
      this.canvasWrapperRef,
      this.exportWidth,
      this.exportHeight
    );

    this.brushDictionary = _initBrushes(
      this.fabricCanvas,
      _initialState.brushMode
    );

    this.setupEventListeners((action: CanvasAction) => {
      this.dispatch(action);
    }, this.onObjectAdded.bind(this));

    _loadFonts();
  }

  /* eslint-disable no-param-reassign */
  private onObjectAdded(newObject: fabric.Object | undefined): void {
    if (!newObject) return;

    switch (newObject.type) {
      case 'path':
      case 'group':
        newObject.selectable = false;
        newObject.evented = false;
        newObject.hoverCursor = 'default';
        break;

      default:
        break;
    }

    this.selectedObjectMemory.push(newObject);
  }
  /* eslint-enable no-param-reassign */

  private setupEventListeners = (
    dispatch: (canvasAction: CanvasAction) => any,
    onObjectAdded: (newObject: fabric.Object | undefined) => any
  ) => {
    this.fabricCanvas.on('mouse:down', (evt) => {
      const selectedObject = evt.target ? evt.target : null;

      dispatch({
        type: 'onMouseDown',
        selectedObject,
      });
    });

    this.fabricCanvas.on('after:render', () => {
      _positionHiddenTextarea(this.selectedObjectMemory);
    });

    this.fabricCanvas.on('mouse:up', () => {
      _positionHiddenTextarea(this.selectedObjectMemory);

      dispatch({
        type: 'onMouseUp',
      });
    });

    this.fabricCanvas.on('object:added', (evt) => {
      const addedObject = evt.target;
      onObjectAdded(addedObject as fabric.Object);
      dispatch({
        type: 'onObjectAdded',
      });
    });

    this.fabricCanvas.on('object:removed', () => {
      dispatch({
        type: 'onObjectRemoved',
        canvasMemory: this.fabricCanvas.getObjects(),
      });
    });

    window.addEventListener('resize', this.onWindowResize);
    window.addEventListener('keydown', this.onKeyDown);
  };

  private destroyEventListeners = () => {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('keydown', this.onKeyDown);
  };

  private onWindowResize = () => {
    setCanvasToFitWrapperSize(
      this.fabricCanvas,
      this.wrapperRef,
      this.canvasWrapperRef,
      this.exportWidth,
      this.exportHeight
    );
  };

  private onKeyDown = (e: any) => {
    switch (e.key) {
      case 'Backspace':
      case 'Delete':
        this.deleteSelectedObject();
        break;
      default:
        break;
    }
  };

  emptyObjectMemory = () => {
    this.selectedObjectMemory = [];
  };

  deleteObjectsInMemory = () => {
    this.selectedObjectMemory.forEach((obj) => {
      this.fabricCanvas.remove(obj);
    });
  };

  destroyCanvas = () => {
    this.destroyEventListeners();
    delete this.redoMemory;
    delete this.fabricCanvas;
  };

  addImage = (imgUrl: string, cb: (imageObject: fabric.Object) => void) => {
    _addImageObject(this.fabricCanvas, imgUrl, cb);

    this.cleanRedoMemory();
  };

  addSelectionToMemory = (objectList: [CanvasState['selectedObject']]) => {
    this.selectedObjectMemory = objectList.filter(
      (obj): obj is fabric.Object => obj !== null
    );
  };

  createText = (): fabric.Object => {
    const textObj = _createText(this.fabricCanvas);
    this.cleanRedoMemory();

    return textObj;
  };

  focusOn = (obj: fabric.Object): fabric.Object => {
    _focusOn(this.fabricCanvas, obj);
    return obj;
  };

  /* eslint-disable no-param-reassign */
  changeFontOn = (textObj: fabric.Textbox, newFont: string) => {
    textObj.fontFamily = newFont;
    this.fabricCanvas.renderAll();
  };
  /* eslint-enable no-param-reassign */

  /* eslint-disable no-param-reassign */
  changeFontSizeOn = (textObj: fabric.Textbox, newFontSize: number) => {
    textObj.fontSize = newFontSize;
    this.fabricCanvas.renderAll();
  };
  /* eslint-enable no-param-reassign */

  setTextAlignmentOn = (
    textObj: fabric.Textbox,
    textAlign: CanvasState['_contextTextAlign']
  ): void => {
    textObj.set('textAlign', textAlign);
    this.fabricCanvas.renderAll();
  };

  deselectAll = () => {
    _deselectAll(this.fabricCanvas);
  };

  deleteAllCanvasObjects = () => {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects());
    this.dispatch({ type: 'setSelectedObject', selectedObject: null });
  };

  deleteSelectedObject = () => {
    const activeObject: fabric.Object = this.fabricCanvas.getActiveObject();

    if (activeObject && !activeObject.isEditing) {
      this.fabricCanvas.remove(activeObject);
      this.dispatch({ type: 'setSelectedObject', selectedObject: null });
    }
  };

  setDrawingMode = (isDrawingMode: boolean) => {
    this.fabricCanvas.isDrawingMode = isDrawingMode;
  };

  setBrushMode = (newBrushMode: BrushMode, color: string): void => {
    _setBrushMode.call(this, newBrushMode, color);
  };

  setColorFor(
    contextObject: CanvasState['selectedObject'],
    selectedColor: string
  ): void {
    if (contextObject === null) {
      _setBrushColor.call(this, selectedColor);
      return;
    }

    if (
      contextObject.type &&
      ['text', 'textbox'].includes(contextObject.type)
    ) {
      contextObject.set('fill', selectedColor);
      this.fabricCanvas.renderAll();
    }
  }

  setBrushSizeOn(brushMode: BrushMode, newSize: number): void {
    this.brushDictionary[brushMode].width = newSize;
  }

  setHighlightColorOn = (
    textObj: fabric.Textbox,
    highlightColor: string | undefined
  ): void => {
    textObj.set('textBackgroundColor', highlightColor);
    this.fabricCanvas.renderAll();
  };

  /* eslint-disable no-param-reassign */
  setObjectsIsSelectable(isSelectable: boolean) {
    this.fabricCanvas.getObjects().forEach((ch) => {
      if (ch.type && ['path', 'group'].includes(ch.type)) return;
      ch.selectable = isSelectable;
      ch.evented = isSelectable;
      ch.hoverCursor = isSelectable ? 'pointer' : 'default';
    });
  }
  /* eslint-enable no-param-reassign */

  /* eslint-disable no-param-reassign */
  togglePossibleObjectSelection = (
    selectedObject: CanvasState['selectedObject']
  ): void => {
    this.fabricCanvas.getObjects().forEach((ch) => {
      if (
        ch === selectedObject ||
        (ch.type && ['path', 'group'].includes(ch.type))
      ) {
        return;
      }
      ch.selectable = !selectedObject;
      ch.evented = !selectedObject;
      ch.hoverCursor = !selectedObject ? 'pointer' : 'default';
    });
  };
  /* eslint-enable no-param-reassign */

  undoLastAdded = (): void => {
    const allObjects = this.fabricCanvas.getObjects();
    const objectToRemove = allObjects[allObjects.length - 1];

    if (objectToRemove) {
      this.fabricCanvas.remove(objectToRemove);
      this.redoMemory.push(objectToRemove);
      this.dispatch({ type: 'redoMemoryUpdate', redoMemory: this.redoMemory });
    }
  };

  redoFromMemory = (): void => {
    const redoActionObject = this.redoMemory.pop();
    if (redoActionObject) {
      this.fabricCanvas.add(redoActionObject);
    }
    this.dispatch({ type: 'redoMemoryUpdate', redoMemory: this.redoMemory });
  };

  cleanRedoMemory = () => {
    this.redoMemory = [];
    this.dispatch({ type: 'redoMemoryUpdate', redoMemory: this.redoMemory });
  };

  exportPng = (): string => {
    const dataUrl = this.fabricCanvas.toDataURL({
      format: 'png',
      multiplier: 1,
      withoutTransform: true,
    });

    if (this.onPublish) {
      this.onPublish(dataUrl);
    }

    return dataUrl;
  };
}
