import React, {
  useReducer,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { StyledWrapper, StyledCanvasWrapper } from './DrawingPaper.styles';
import FabricCanvasWrapper from './fabricCanvasWrapper/FabricCanvasWrapper';
import { PaperTools } from './paperTools/PaperTools';
import { canvasReducer } from './shared/canvasReducer';
import { CanvasContext } from './shared/canvas.helper';
import { initialState } from './shared/state.helper';
import { useCanvasEffect } from './shared/hooks.helper';

interface DrawingPaperProps {
  onPublish?: (dataUrl: string) => void;
  exportWidth?: number;
  exportHeight?: number;
}

export interface DrawingPaperApi {
  getImageDataUrl: () => string;
}

const DrawingPaperRaw: React.RefForwardingComponent<
  DrawingPaperApi,
  DrawingPaperProps
> = ({ onPublish, exportWidth = 800, exportHeight = 450 }, ref) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasWrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [canvasState, canvasDispatch] = useReducer(canvasReducer, initialState);

  const [
    fabricInstance,
    setFabricInstance,
  ] = useState<FabricCanvasWrapper | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      getImageDataUrl() {
        if (fabricInstance) {
          return fabricInstance.exportPng();
        }
        throw new Error('Expected to have access to a fabricInstance.');
      },
    }),
    [fabricInstance]
  );

  useEffect(() => {
    if (!fabricInstance) {
      setFabricInstance(() => {
        // Should always enter the following if statement
        if (
          canvasRef.current &&
          canvasWrapperRef.current &&
          wrapperRef.current
        ) {
          return new FabricCanvasWrapper(
            canvasRef.current,
            canvasWrapperRef.current,
            wrapperRef.current,
            canvasDispatch,
            {
              ...initialState,
            },
            exportWidth,
            exportHeight,
            onPublish
          );
        }
        throw new Error(
          'The Fabric instance are missing Dom element references when initialized in DrawingPaper'
        );
      });
    }

    return () => {
      if (!fabricInstance) return;
      fabricInstance.destroyCanvas();
    };
  }, [fabricInstance, exportHeight, exportWidth, onPublish]);

  useCanvasEffect(canvasState, fabricInstance);

  return (
    <CanvasContext.Provider value={[canvasState, canvasDispatch]}>
      <StyledWrapper ref={wrapperRef}>
        {fabricInstance ? <PaperTools fabricInstance={fabricInstance} /> : null}
        <StyledCanvasWrapper ref={canvasWrapperRef}>
          <canvas ref={canvasRef} />
        </StyledCanvasWrapper>
      </StyledWrapper>
    </CanvasContext.Provider>
  );
};

export const DrawingPaper = forwardRef(DrawingPaperRaw);
