export function numberSwitch(value: number | null) {
  return (mode: 'moreThan' | 'lessThan', limit: number, cb: () => void) => {
    const comparator =
      value === null
        ? false
        : mode === 'moreThan'
        ? value >= limit
        : value < limit;

    if (comparator) {
      cb();
      return numberSwitch(null);
    }
    return numberSwitch(value);
  };
}
