import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Grid, Typography, Box } from '@material-ui/core';

import {
  DrawingPaper,
  Link,
  Tags,
  InteractiveContentBadge,
  Section,
} from 'components';
import { viewportContext } from 'utils/contexts';

const DrawingPaperWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing(2)}px;
    background-color: ${theme.palette.secondary.main};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1)}px;
    }
  `}
`;

export const CanvasDrawingOverview = () => {
  const { isMobile } = React.useContext(viewportContext);

  return (
    <Section fullHeight>
      <Grid container spacing={isMobile ? 2 : 10}>
        <Grid item sm={12} md={5}>
          <Typography variant="h3">Canvas Drawings</Typography>
          <Typography>
            To draw on the web allows the user to express themselves freely.
            Together with Ralph, the designer, I brought this project to life.
            By utilizing Reacts reducer I could establish a communication
            pipeline between React and the internal state of the HTML5 canvas.
            This project was included in Big White Walls initial launch of their
            2019 platform and later mentioned in the{' '}
            <Link href="https://www.glasgowtimes.co.uk/news/18348390.new-website-giving-24-7-mental-health-support-glasgows-teenagers">
              news
            </Link>
            .
          </Typography>

          <Box marginY={2} />

          <Tags
            tags={[
              { title: 'TypeScript' },
              { title: 'ReactJs' },
              { title: 'HTML5' },
              { title: 'Flux architecture' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <InteractiveContentBadge anchorOrigin={{ horizontal: 'right' }}>
            <DrawingPaperWrapper>
              <DrawingPaper />
            </DrawingPaperWrapper>
          </InteractiveContentBadge>
        </Grid>
      </Grid>
    </Section>
  );
};
