import React from 'react';
import styled from 'styled-components/macro';
import { IconButton } from '@material-ui/core';
import { Done, Delete } from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import { ToolbarWrapper, Right } from './shared/BottomToolbar.styles';

interface BaseToolbarProps {
  show: boolean;
  onDone: () => void;
  onDelete: () => void;
}

const StyledDone =
  styled(Done)`
    color: ${({ theme }) => theme.palette.primary.main};
  ` as React.ComponentType<SvgIconProps>;

export const BaseBottomToolbar: React.FC<BaseToolbarProps> = ({
  children,
  show,
  onDelete,
  onDone,
}) => {
  return (
    <ToolbarWrapper show={show}>
      {children}
      <Right>
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
        <IconButton onClick={onDone}>
          <StyledDone />
        </IconButton>
      </Right>
    </ToolbarWrapper>
  );
};
