import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { Button } from '../Button/Button';

interface ToolbarButtonProps {
  children?: any;
  className?: string;

  onClick?: () => void;
  isActive?: boolean;
}

const StyledButtonWrapper = styled.span<{ isActive: boolean }>`
  display: flex;
  flex: 1 0 64px;
  justify-content: center;

  max-width: 100px;
  margin: ${({ theme }) => theme.spacing(1)}px 0;
  border-right: 2px solid #e8e8e8;

  button {
    ${({ theme, isActive }) => css`
      border-radius: 0;
      border-bottom: solid ${theme.palette.primary.main} ${isActive ? '5px' : 0};
    `}
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  width: 100%;
  height: 100%;
`;

const NegativeMargin = styled.span`
  display: flex;
  width: 100%;
  margin: -${({ theme }) => theme.spacing(1)}px;
`;

/** A simple implementation of button, used for inline toolbars (i.e. Drawing Paper) */
export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  children,
  className,
  onClick,
  isActive = false,
}) => {
  return (
    <StyledButtonWrapper className={className} isActive={isActive}>
      <NegativeMargin>
        <StyledButton
          onClick={onClick}
          color="default"
          variant="text"
          size="small"
          fullWidth
        >
          {children}
        </StyledButton>
      </NegativeMargin>
    </StyledButtonWrapper>
  );
};
