import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Typography, Box } from '@material-ui/core';

import { Link } from 'components';

const Wrapper = styled.div`
  ${({ theme }) => css`
    grid-row: 5;
    grid-column: 3 / span 9;

    ${theme.breakpoints.down('sm')} {
      grid-column: 2 / span 10;
    }
  `}
`;

export const HeroTitle = () => {
  return (
    <Wrapper>
      <Typography variant="h1">
        Viktor Leandersson,
        <br />
        Software Engineer
      </Typography>
      <Box paddingBottom={4} />
      <Typography variant="h2">
        Formerly at <Link href="https://dynabyte.se/">Dynabyte</Link>
        {', '}
        <Link href="https://neverbland.com/">NeverBland</Link> and{' '}
        <Link href="https://tractable.ai/">Tractable</Link>
      </Typography>
    </Wrapper>
  );
};
