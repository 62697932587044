import React from 'react';
import styled, { css } from 'styled-components/macro';
import Prism from 'prismjs';
import {
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Box,
} from '@material-ui/core';

import { Button, Chip, Code } from 'components';
import { AlgorithmBase } from 'models';
import { useHistory } from 'react-router-dom';
import { routes } from 'Routes';
import { rgba } from 'polished';

const CardHeader = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(1)}px;

    background-color: ${theme.palette.common.black};
  `}
`;

const Item = styled.li`
  display: flex;
  align-items: stretch;

  grid-template-columns: 1fr;
`;

const StyledFigure = styled.figure`
  ${({ theme }) => css`
    position: relative;

    height: 100%;
    max-height: 200px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        ${rgba(theme.palette.common.black, 0)},
        ${rgba(theme.palette.common.black, 1)} 75%
      );
      width: 100%;
      height: 70px;
    }
  `}
`;

const StyledPre = styled.pre`
  ${({ theme }) => css`
    && {
      height: 100%;
      border-radius: 0;
      padding: ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(8)}px;
      margin: 0;
    }
  `}
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: flex-end;
  padding-top: 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCardActionArea = styled(CardActionArea)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledCardContent = styled(CardContent)`
  ${({ theme }) => css`
    padding: ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px;
  `}
`;

interface AlgorithmProps
  extends Pick<
    AlgorithmBase,
    'id' | 'name' | 'codeSummary' | 'problemDomain' | 'difficulty'
  > {}

export const AlgorithmCard: React.FC<AlgorithmProps> = ({
  id,
  name,
  codeSummary,
  problemDomain,
  difficulty,
}) => {
  const history = useHistory();

  React.useLayoutEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <Item>
      <StyledCard>
        <StyledCardActionArea
          onClick={() => history.push(routes.algorithm.build({ id }))}
        >
          <CardHeader>
            <Chip label={problemDomain} rounded color="primary" />
          </CardHeader>
          <StyledFigure>
            <StyledPre>
              <Code>{codeSummary}</Code>
            </StyledPre>
          </StyledFigure>

          <StyledCardContent>
            <Box fontWeight="bold">
              <Typography variant="h6" variantMapping={{ h6: 'h3' }}>
                {name}
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              {difficulty}
            </Typography>
          </StyledCardContent>
        </StyledCardActionArea>
        <StyledCardActions>
          <Button>View more</Button>
        </StyledCardActions>
      </StyledCard>
    </Item>
  );
};
