import React from 'react';
import { IconButton } from '@material-ui/core';
import { AddPhotoAlternate } from '@material-ui/icons';

interface UploadImageProps {
  onUploadImage: (imgUrl: string) => void;
}

interface FileReaderEventTarget extends EventTarget {
  result: string;
}

export const UploadImage: React.FC<UploadImageProps> = ({ onUploadImage }) => {
  // Handles one file only
  function readURL(evt: React.ChangeEvent<HTMLInputElement>) {
    if (!evt.target || !evt.target.files) return;
    const file = evt.target.files[0];

    // Clear event target to enable uploading an image several times in a row
    // eslint-disable-next-line no-param-reassign
    evt.target.value = '';

    // Silent exit if the user does not select a file
    if (file === undefined) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (e: ProgressEvent) => {
      if (!e.target) return;
      onUploadImage((e.target as FileReaderEventTarget).result);
    };

    reader.readAsDataURL(file);
  }

  return (
    <label htmlFor="image-fileupload">
      <input
        accept="image/gif, image/jpeg, image/png"
        style={{ display: 'none' }}
        id="image-fileupload"
        type="file"
        onChange={(e) => readURL(e)}
      />
      <IconButton component="span">
        <AddPhotoAlternate />
      </IconButton>
    </label>
  );
};
