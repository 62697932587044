import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Box } from '@material-ui/core';
import { routes } from 'Routes';
import { Link } from '../../components';

const NavWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    justify-content: center;
  `}
`;

const StyledNav = styled.nav`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    align-items: stretch;

    width: 100%;

    max-width: ${theme.breakpoints.values.sm}px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;

      font-size: ${theme.typography.h3.fontSize};
    }
  `}
`;

const LinkWrapper = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;

    a {
      text-decoration: none;
    }
  `}
`;

const NavigationRaw: React.ForwardRefRenderFunction<HTMLDivElement> = (
  _,
  ref
) => {
  return (
    <NavWrapper ref={ref}>
      <StyledNav>
        <LinkWrapper padding={1}>
          <Link to={routes.home.build()}>Portfolio</Link>
        </LinkWrapper>

        {/* <LinkWrapper padding={1}>
          <Link to={routes.javaScriptLibrary}>JavaScript Library</Link>
        </LinkWrapper> */}

        <LinkWrapper padding={1}>
          <Link to={routes.algorithmLibrary.build()}>Algorithms Library</Link>
        </LinkWrapper>
      </StyledNav>
    </NavWrapper>
  );
};

export const Navigation = React.forwardRef(NavigationRaw);
