import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { GlobalDrawers } from 'screens';
import { Routes } from './Routes';
import { Providers } from './utils';
import { Navbar } from './views';

function App() {
  return (
    <Router>
      <Providers>
        <GlobalDrawers />
        <Navbar />
        <Routes />
      </Providers>
    </Router>
  );
}

export default App;
