import React from 'react';
import styled from 'styled-components/macro';
// import { CarouselNavigator } from './shared/CarouselNavigator';

const Wrapper = styled.div`
  height: 100%;
`;

interface SwipeableContentProps {}

export const SwipeableContent: React.FC<SwipeableContentProps> = ({
  children,
}) => {
  return (
    <Wrapper>
      {children}
      {/* <CarouselNavigator /> */}
    </Wrapper>
  );
};
