/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { buildRoutesApi } from 'typed-named-routes';

import {
  PortfolioScreen,
  AlgorithmLibraryScreen,
  AlgorithmScreen,
} from './screens';

const routeDefinitions = {
  home: () => '/',
  javaScriptLibrary: () => '/javascript',
  algorithm: (params: { id: string }) => `/algorithms/:id`,
  algorithmLibrary: () => `/algorithms`,
};

export const routes = buildRoutesApi(routeDefinitions);

export const Routes = () => {
  return (
    <Switch>
      <Route exact path={routes.home.template}>
        <PortfolioScreen />
      </Route>
      <Route exact path={routes.algorithm.template}>
        <AlgorithmScreen />
      </Route>
      <Route exact path={routes.algorithmLibrary.template}>
        <AlgorithmLibraryScreen />
      </Route>
      <Route>404</Route>
    </Switch>
  );
};
