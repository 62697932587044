import React from 'react';
import { AlgorithmBase } from '..';

const codeSummary = `// Women (to shake things up a bit)
const proposers = new Map([
  ["a", ["o", "m", "n", "l", "p"]],
  ["b", ["p", "n", "m", "k", "o"]],
  ["c", ["m", "p", "l", "o", "n"]],
  ["d", ["p", "m", "o", "n", "l"]],
  ["e", ["o", "l", "m", "n", "p"]]
]);

// Men
const receivers = new Map([
  ["l", ["d", "b", "e", "c", "a"]],
  ["m", ["b", "a", "d", "c", "e"]],
  ["n", ["a", "c", "e", "d", "b"]],
  ["o", ["d", "a", "c", "b", "e"]],
  ["p", ["b", "e", "a", "c", "d"]]
]);

const S = galeShapley(proposers, receivers);`;

const code = `function galeShapley(p, r) {
  // Initialize everyone as unmatched
  const unmatchedProposers = new Set(p.keys());
  const unmatchedReceivers = new Set(r.keys());

  // Married
  const S = new Map();

  // The algorithm O(n^2)
  while (unmatchedProposers.size) {
    // Get a proposer from the unmatched set
    const m = unmatchedProposers.keys().next().value;

    // Get the top rated match that is still left
    const w = proposers.get(m).shift();

    // If the match is still unmatched
    if (unmatchedReceivers.has(w)) {
      // pair w and m together and remove them from the unmatched Map
      S.set(w, m);
      unmatchedProposers.delete(m);
      unmatchedReceivers.delete(w);
    } else {
      // Get w current match m'
      const mPrime = S.get(w);

      // w's preferences
      const p = receivers.get(w);

      // w prefers m to m' (higher index is less worth)
      if (p.indexOf(mPrime) > p.indexOf(m)) {
        // then match w and m, m' becomes unmatched again
        S.delete(w);
        unmatchedProposers.add(mPrime);
        unmatchedProposers.delete(m);
        S.set(w, m);
      } else {
        // m continues to be unmatched
      }
    }
  }

  return S;
}`;

export const galeShapley: AlgorithmBase = {
  id: 'gale-shapley',
  name: 'Gale-Shapley',
  difficulty: 'easy',
  problemDomain: 'stable matching problem',
  code,
  codeSummary,
  timeComplexityDomain: 'P',
  timeComplexity: (
    <>
      O(n+m) = O(n<sup>2</sup>)
    </>
  ),
  description: 'A common algorithm for sorting the stable matching problem.',
};
