import React from 'react';
import styled, { css } from 'styled-components/macro';

import { AlgorithmCard } from 'components';

import { algorithms } from 'models';

const Wrapper = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: ${theme.spacing(4)}px;

    /* Reset ul element */
    list-style: none;
    padding-left: 0;
  `}
`;

export const AlgorithmLibrary: React.FC = () => {
  return (
    <Wrapper>
      {algorithms.map((a) => (
        <AlgorithmCard key={a.id} {...a} />
      ))}
    </Wrapper>
  );
};
