import React, { useContext } from 'react';
import { BaseBottomToolbar } from './BaseBottomToolbar';
import { ButtonSlider } from './shared/ButtonSlider';
import { BrushMode } from '../shared/types';
import { BwwPen, BwwPencil, BwwBrush } from '../../Icon/Icon';
import { ColorSwatchLibrary } from './ColorSwatchLibrary';
import {
  ToolbarFlexContainer,
  FlexBlock,
  Left,
} from './shared/BottomToolbar.styles';
import { CanvasContext } from '../shared/canvas.helper';

interface Props {
  onBrushSizeChange: (newSize: number, brushMode?: BrushMode) => void;
  onDone: () => void;
  onDelete: () => void;
  show: boolean;
  brushDictionary: { [id in BrushMode]: any };
}

export const DrawToolbar: React.FC<Props> = ({
  onBrushSizeChange,
  onDone,
  onDelete,
  brushDictionary,
  show,
}) => {
  const [canvasState, canvasDispatch] = useContext(CanvasContext);

  function onDrawModeChange(newMode: BrushMode) {
    canvasDispatch({
      type: 'setBrushMode',
      newMode,
    });
  }

  function onColorSelect(color: string) {
    canvasDispatch({
      type: 'setContextVars',
      payload: {
        _contextSelectedColor: color,
      },
    });
  }

  function getButtonList() {
    const elements: JSX.Element[] = [];
    const brushIconList: any[] = [
      <BwwPencil key="BwwPencil" />,
      <BwwPen key="BwwPen" />,
      <BwwBrush key="BwwBrush" />,
    ];

    Object.keys(brushDictionary).forEach((key, index) => {
      if (!brushDictionary[key]) {
        return;
      }

      elements.push(
        <ButtonSlider
          active={canvasState._contextBrushMode === key}
          initialValue={brushDictionary[key].width}
          min={1}
          max={25}
          onChange={onBrushSizeChange}
          onClick={() => onDrawModeChange(key as BrushMode)}
          id={key}
          key={key}
        >
          {brushIconList[index]}
        </ButtonSlider>
      );
    });

    return elements;
  }

  return (
    <ToolbarFlexContainer>
      <FlexBlock>
        <ColorSwatchLibrary
          show={show}
          onColorSelect={onColorSelect}
          selectedColor={
            typeof canvasState._contextSelectedColor === 'string'
              ? canvasState._contextSelectedColor
              : undefined
          }
        />
      </FlexBlock>
      <BaseBottomToolbar show={show} onDone={onDone} onDelete={onDelete}>
        <Left>{getButtonList()}</Left>
      </BaseBottomToolbar>
    </ToolbarFlexContainer>
  );
};
