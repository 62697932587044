import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Card, Typography } from '@material-ui/core';

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;

    height: 100%;
  `}
`;

const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2)}px;
    border-radius: 0 0 50% 0;

    background-color: ${theme.palette.secondary.main};

    svg {
      color: ${theme.palette.common.white};
    }
  `}
`;

const Content = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing(2)}px;
  `}
`;

interface InfoCardProps {
  title: React.ReactNode;
  info?: React.ReactNode;
  icon?: React.ReactNode;
}

export const InfoCard: React.FC<InfoCardProps> = ({ title, info, icon }) => {
  return (
    <StyledCard>
      <IconWrapper>{icon}</IconWrapper>

      <Content>
        <Typography variant="body2" variantMapping={{ body2: 'h6' }}>
          {title}
        </Typography>

        <Typography variant="h6" variantMapping={{ h6: 'body1' }}>
          {info ?? '-'}
        </Typography>
      </Content>
    </StyledCard>
  );
};
