import { ComponentType } from 'react';
import styled, { css } from 'styled-components/macro';
import { readableColor } from 'polished';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
} from '@material-ui/icons';
import { ToolbarButton } from '../..';
import { ContextState } from '../shared/types';

export const FontFamilyButton = styled(ToolbarButton)<{ fontFamily: string }>`
  button {
    font-family: ${(props) => props.fontFamily};
    font-size: ${({ theme }) => theme.typography.h3.fontSize};

    line-height: 1;
  }
`;

export const StyledHighlightButton = styled(ToolbarButton)<{
  fillColor: string | undefined;
}>`
  ${({ fillColor }) => css`
    svg {
      fill: ${fillColor || ''};

      /* Styles text to have readable color */
      path#Aa {
        fill: ${fillColor ? readableColor(fillColor) : ''};
      }
    }
  `}
`;

export const alignIconDictionary: Partial<
  { [id in ContextState['_contextTextAlign']]: ComponentType<SvgIconProps> }
> = {
  center: FormatAlignCenter,
  left: FormatAlignLeft,
  right: FormatAlignRight,
};
